import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './DateRangePicker.module.scss'

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: props.startDateDefaultValue,
      endDate: props.endDateDefaultValue,
    }

    this.tempStartDate = props.startDateDefaultValue
    this.tempEndDate = props.endDateDefaultValue
  }

  handleStartDateChange = (startDate) => {
    const endDate = this.state.endDate >= startDate ? this.state.endDate : undefined
    this.setState({startDate: startDate ?? undefined, endDate}, () =>
      this.props.onChange(this.state.startDate, endDate),
    )
  }

  handleEndDateChange = (endDate) => {
    const startDate = this.state.startDate <= endDate ? this.state.startDate : undefined
    this.setState({startDate, endDate: endDate ?? undefined}, () => this.props.onChange(startDate, this.state.endDate))
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <DatePicker
            dateFormat={this.props.dateFormat ?? 'MMMM yyyy'}
            placeholderText="From"
            selected={this.state.startDate}
            // onChange is not suitable for typing the date instead of selecting it
            // onChange={(date) => this.handleStartDateChange(date)}
            onChange={(date) => (this.tempStartDate = date)}
            onBlur={(e) => this.handleStartDateChange(this.tempStartDate)}
            onSelect={(date) => this.handleStartDateChange(date)}
            showYearPicker={this.props.isYear}
            showMonthYearPicker={!this.props.isYear}
            selectsStart
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            maxDate={this.props.maxStartDate}
            minDate={this.props.minStartDate}
          />
        </div>
        <div>-</div>
        <div className={styles.container}>
          <DatePicker
            dateFormat={this.props.dateFormat ?? 'MMMM yyyy'}
            placeholderText="To"
            selected={this.state.endDate}
            // onChange is not suitable for typing the date instead of selecting it
            // onChange={(date) => this.handleEndDateChange(date)}
            onChange={(date) => (this.tempEndDate = date)}
            onBlur={(e) => this.handleEndDateChange(this.tempEndDate)}
            onSelect={(date) => this.handleEndDateChange(date)}
            showYearPicker={this.props.isYear}
            showMonthYearPicker={!this.props.isYear}
            selectsEnd
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            maxDate={this.props.maxEndDate}
            minDate={this.props.minEndDate}
          />
        </div>
      </div>
    )
  }
}

export default DateRangePicker
