import React from 'react'
import {Layout} from './components/Layout'
import {connect} from 'react-redux'
import {ProofreadTabs} from './components/proofread/ProofreadTabs'
import BioTab from './components/proofread/BioTab'
import ExperienceTab from './components/proofread/ExperienceTab'
import PreviousExperienceTab from './components/proofread/PreviousExperienceTab'
import AccomplishmentsTab from './components/proofread/AccomplishmentsTab'

function Proofread({activeTab}) {
  const currentTab = activeTab || 'Bio'
  return (
    <Layout title={'Proofreading'} needsContent>
      <ProofreadTabs />
      <div>
        {currentTab === 'Bio' && <BioTab />}
        {currentTab === 'Experience' && <ExperienceTab />}
        {currentTab === 'Previous Experience' && <PreviousExperienceTab />}
        {currentTab === 'Accomplishments' && <AccomplishmentsTab />}
      </div>
    </Layout>
  )
}

export default connect((state) => ({
  activeTab: state.proofreadTab.activeTab,
}))(Proofread)
