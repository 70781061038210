import React from 'react'
import {Markdown} from '../Markdown'
import styles from './Extras.module.scss'

export const Extras = ({text}) => (
  <div className={styles.extras}>
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Extras</h2>
      <Markdown source={text} className={styles.content} />
    </div>
  </div>
)
