export const KEY_UPDATE_PROFILE_TAB = 'updateProfileTab'

const getInitialState = () => ({
  activeTab: '',
})

export const setInitialState = (state) => {
  state[KEY_UPDATE_PROFILE_TAB] = getInitialState()
  return state
}
