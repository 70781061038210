import React from 'react'
import {Redirect} from 'react-router-dom'
import {About} from './components/person/About'
import {ProjectsBox} from './components/person/ProjectsBox'
import {EducationBox} from './components/person/EducationBox'
import {AccomplishmentsBox} from './components/person/AccomplishmentsBox'
import SkillsBox from './components/person/SkillsBox'
import {Layout} from './components/Layout'
import {Breadcrumbs} from './components/Breadcrumbs'
import {Header} from './components/person/Header'
import {connect} from 'react-redux'
import styles from './Person.module.scss'
import {ProfileNav} from './components/person/ProfileNav'
import {ProfileSubmenu} from './components/person/ProfileSubmenu'
import {KEY_PERSON} from './state/person'
import {isEmpty, sortBy} from 'lodash'
import logoImage from './assets/img/logo.svg'
import {PriorityExperienceBox} from './components/person/PriorityExperienceBox'
import {parseDateShort} from './components/helpers'

class Person extends React.Component {
  constructor(props) {
    super(props)
    this.onScroll = this.onScroll.bind(this)
  }

  onScroll = function () {
    const {toggleNavigation, isVisible} = this.props
    const pagePosition = window.pageYOffset
    const navScrollBreakpoint = 410

    if (pagePosition > navScrollBreakpoint && !isVisible) {
      toggleNavigation(true)
    }

    if (pagePosition <= navScrollBreakpoint && isVisible) {
      toggleNavigation(false)
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.onScroll, false)
  }

  componentWillUnmount() {
    const {toggleNavigation} = this.props

    window.removeEventListener('scroll', this.onScroll, false)
    toggleNavigation(false)
  }

  render() {
    const {slugAccessible, data, isVisible} = this.props

    const priorityExperience =
      data?.workExperience
        .filter((experience) => experience.priority)
        .concat(data?.previousExperience.filter((experience) => !!experience.priority))
        .sort((a, b) => b.priority - a.priority) ?? []

    const workExperience = data?.workExperience.filter((experience) => !experience.priority) ?? []
    const previousExperience = data?.previousExperience.filter((experience) => !experience.priority) ?? []
    const experience = sortBy(workExperience.concat(previousExperience), (exp) =>
      parseDateShort(exp.startDate),
    ).reverse()
    const hasPriorityExperience = !isEmpty(priorityExperience)

    return !slugAccessible ? (
      <Redirect to="/people" />
    ) : (
      <Layout title={`Vacuumpeople - ${data.name}`} needsContent>
        <ProfileNav
          name={data.name}
          cvId={data.cvId}
          priorityExperience={priorityExperience}
          workExperience={workExperience}
          previousExperience={previousExperience}
          education={data.education}
          isVisible={isVisible}
        />
        <Breadcrumbs category="People" categorySlug="/people" lastItem={data.name} />
        <div className={styles.banner}>
          <img src={logoImage} alt="vacuumlabs" />
          <span className={styles.motto}>We put the Tech in FinTech</span>
        </div>
        <Header name={data.name} position={data.position} image={data.photo} cvId={data.cvId} />
        <div className={styles.wrapper}>
          <div className={styles.leftSide}>
            <About
              contract={data.contract}
              location={data.location}
              bio={data.bio}
              gitHubProfile={data.gitHubProfile}
              slackUserId={data.slackUserId}
            />
            {(data.technicalSkills || data.languageSkills) && (
              <SkillsBox technicalSkills={data.technicalSkills} languageSkills={data.languageSkills} />
            )}
            {!isEmpty(data.accomplishments) && <AccomplishmentsBox accomplishments={data.accomplishments} />}
          </div>
          <div className={styles.rightSide}>
            <ProfileSubmenu
              priorityExperience={priorityExperience}
              workExperience={workExperience}
              previousExperience={previousExperience}
              education={data.education}
            />

            {hasPriorityExperience && (
              <PriorityExperienceBox id="priorityExperience" experience={priorityExperience} name="My Experience" />
            )}
            {!isEmpty(experience) && (
              <ProjectsBox
                id="projects"
                experience={experience}
                title={hasPriorityExperience ? 'Other Experience' : 'My Experience'}
              />
            )}

            {!isEmpty(data.education) && <EducationBox id="education" education={data.education} />}
            {data.hash && (
              <div id="person-hash" style={{display: 'none'}}>
                {data.hash}
              </div>
            )}
          </div>
        </div>
        <iframe id="pdf-downloader" style={{display: 'none'}} title="pdf downloader" />
      </Layout>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleNavigation: (isVisible) =>
      dispatch({
        type: 'Show profile navigation',
        path: [KEY_PERSON],
        reducer: (state) => ({
          ...state,
          isVisible,
        }),
      }),
  }
}

export default connect(
  (
    state,
    {
      match: {
        params: {slug},
      },
    },
  ) => ({
    slugAccessible: Object.prototype.hasOwnProperty.call({...state.accessiblePeople, ...state.offboardedPeople}, slug),
    data: state.accessiblePeople[slug] || state.offboardedPeople[slug],
    ...state[KEY_PERSON],
  }),
  mapDispatchToProps,
)(Person)
