import React from 'react'
import {Layout} from './components/Layout'
import styles from './Loading.module.scss'

export const Loading = () => (
  <Layout title="Vacuumpeople">
    <div className={styles.loading}>
      <div className={styles.loader} />
    </div>
  </Layout>
)
