import React, {useEffect} from 'react'
import styles from './Dialog.module.scss'

export const Dialog = ({title, children, open, setClose}) => {
  // disabling scroll on body if dialog is opened and restoring scroll when closed
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'inherit'
    }
  }, [open])

  return (
    <>
      {open && (
        <div className={styles.wrapper}>
          <div className={styles.overlay} onClick={setClose} />
          <div className={styles.dialog}>
            <div className={styles.title}>
              <h2>{title}</h2>
              <button className={styles.closeIcon} onClick={setClose}>
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="#e72076">
                  <path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z" />
                </svg>
              </button>
            </div>
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      )}
    </>
  )
}
