import React from 'react'
import {Markdown} from '../Markdown'
import {parseDuration, parseUrl} from '../helpers'
import styles from './Header.module.scss'
import {getHeroImageByProjectId} from '../../helpers/util'

export const Header = ({id, clientsLogo, heroImage, heading, subheading, startDate, endDate, url, children}) => {
  const style = {
    backgroundImage: `url(${heroImage || getHeroImageByProjectId(id)})`,
  }
  const duration = parseDuration(startDate, endDate)
  return (
    <header className={styles.header} style={style}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          {duration && (
            <p>
              <strong>Duration:</strong> {duration}
            </p>
          )}
          {url && (
            <p>
              <strong>Web site:</strong>{' '}
              <a href={url} target="_blank" rel="noopener noreferrer">
                {parseUrl(url)}
              </a>
            </p>
          )}
        </div>
        <div className={styles.children}>{children}</div>
        {clientsLogo && <img src={clientsLogo} alt="" className={styles.image} />}
        <h1 className={styles.title}>{heading}</h1>
        {subheading && (
          <h2 className={styles.subtitle}>
            <Markdown source={subheading} />
          </h2>
        )}
      </div>
    </header>
  )
}
