import React from 'react'
import {Layout} from './components/Layout'
import Login from './components/Login'
import styles from './Auth.module.scss'

const Auth = () => (
  <Layout title="Vacuumpeople">
    <div className={styles.wrapper}>
      <Login />
    </div>
  </Layout>
)

export default Auth
