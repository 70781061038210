import React, {Fragment} from 'react'
import axios from 'axios'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import classNames from 'classnames'
import MediaQuery from 'react-responsive'
import styles from './HeaderNav.module.scss'
import {KEY_HEADER_NAV} from '../state/headerNav'
import logoImage from '../assets/img/logo.svg'

const desktopBreakpoint = 1124 /* See HeaderNav.scss*/

const Logo = ({onClick}) => (
  <NavLink to="/" className={styles.logoDesktop} onClick={onClick}>
    <img src={logoImage} alt="Vacuumpeople" className={styles.logo} />
  </NavLink>
)

const NavigationLink = ({to, isActive, children}) => (
  <li className={styles.item}>
    <NavLink to={to} className={styles.link} activeClassName={styles.active} isActive={isActive}>
      {children}
    </NavLink>
  </li>
)

const NavContent = ({admin, proofreader, linkToken}) => (
  <Fragment>
    {!linkToken && <NavigationLink to="/people">People</NavigationLink>}
    {!linkToken && <NavigationLink to="/organisation">Org Structure</NavigationLink>}
    {!linkToken && <NavigationLink to="/projects">Projects</NavigationLink>}
    {proofreader && <NavigationLink to="/proofread">Proofread</NavigationLink>}
    {admin && <NavigationLink to="/usermanagement">Users</NavigationLink>}
  </Fragment>
)

const Nav = ({admin, proofreader, linkToken}) => (
  <ul className={styles.nav}>
    <NavContent admin={admin} proofreader={proofreader} linkToken={linkToken} />
  </ul>
)

export const AppNav = ({loggedIn, admin, refresh, isRefreshing}) => (
  <ul className={styles.nav}>
    {loggedIn && (
      <li className={styles.username}>
        <NavLink to="/profile" className={styles.usernamelink}>
          My Profile
        </NavLink>
      </li>
    )}
    <li className={styles.item}>
      {loggedIn ? (
        admin ? (
          isRefreshing ? (
            <span className={styles.link}>Refreshing</span>
          ) : (
            <button className={styles.link} onClick={() => refresh()}>
              Refresh
            </button>
          )
        ) : (
          <a href="/logout" className={styles.link}>
            Logout
          </a>
        )
      ) : (
        <a href="/login" className={styles.link}>
          Login
        </a>
      )}
    </li>
  </ul>
)

export const CollapsibleNav = ({
  loggedIn,
  proofreader,
  admin,
  linkToken,
  showMenu,
  hideMenu,
  refresh,
  isRefreshing,
}) => (
  <div className={classNames(styles.navCollapsible, !showMenu && styles.collapsed, !loggedIn && styles.onlyLoginLink)}>
    <ul onClick={() => hideMenu()}>
      {loggedIn ? (
        <Fragment>
          <NavContent admin={admin} proofreader={proofreader} linkToken={linkToken} />
          <li className={styles.footer}>
            <span className={styles.username}>
              <NavLink to="/profile" className={styles.usernamelink}>
                My Profile
              </NavLink>
            </span>
            {admin ? (
              isRefreshing ? (
                <span className={styles.item}>Refreshing</span>
              ) : (
                <span className={styles.item} onClick={() => refresh()}>
                  Refresh
                </span>
              )
            ) : (
              <span className={styles.item}>
                <a href="/logout" className={styles.link}>
                  Logout
                </a>
              </span>
            )}
          </li>
        </Fragment>
      ) : (
        <li className={classNames(styles.item, styles.login)}>
          <a href="/login" className={styles.link}>
            Login
          </a>
        </li>
      )}
    </ul>
  </div>
)

const _HeaderNav = ({
  loggedIn,
  proofreader,
  userName,
  admin,
  linkToken,
  showMenu,
  toggleMenu,
  hideMenu,
  isActive,
  refresh,
  isRefreshing,
}) => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <Logo onClick={() => hideMenu()} />
      <div className={styles.navs}>
        <MediaQuery minWidth={desktopBreakpoint}>
          {loggedIn && <Nav admin={admin} proofreader={proofreader} linkToken={linkToken} />}
          <AppNav
            loggedIn={loggedIn}
            admin={admin}
            proofreader={proofreader}
            linkToken={linkToken}
            userName={userName}
            refresh={refresh}
            isRefreshing={isRefreshing}
          />
        </MediaQuery>
        <button
          type="button"
          className={classNames(styles.navbarToggle, isActive && styles.isActive)}
          onClick={() => toggleMenu()}
        >
          <span className="sr-only">Toggle navigation</span>
          <span className={styles.iconBar} />
          <span className={styles.iconBar} />
          <span className={styles.iconBar} />
        </button>
      </div>
    </div>
    <MediaQuery maxWidth={desktopBreakpoint}>
      <CollapsibleNav
        loggedIn={loggedIn}
        userName={userName}
        admin={admin}
        proofreader={proofreader}
        linkToken={linkToken}
        showMenu={showMenu}
        hideMenu={hideMenu}
        refresh={refresh}
        isRefreshing={isRefreshing}
      />
    </MediaQuery>
  </div>
)

export const HeaderNav = connect(
  (state) => ({
    loggedIn: state.loggedIn,
    userName: state.user.username,
    admin: state.user.admin,
    proofreader: state.user.proofreader,
    linkToken: state.user.linkToken,
    showMenu: state.headerNav.showMenu,
    isActive: state.headerNav.isActive,
    isRefreshing: state.headerNav.isRefreshing,
  }),
  (dispatch) => ({
    toggleMenu: () =>
      dispatch({
        type: 'Toggle menu',
        path: [KEY_HEADER_NAV],
        reducer: (state) => ({
          ...state,
          showMenu: !state.showMenu,
          isActive: !state.isActive,
        }),
      }),
    hideMenu: () =>
      dispatch({
        type: 'Hide menu',
        path: [KEY_HEADER_NAV],
        reducer: (state) => ({
          ...state,
          showMenu: false,
          isActive: false,
        }),
      }),
    refresh: () => {
      dispatch({
        type: 'Refresh',
        path: [KEY_HEADER_NAV],
        reducer: (state) => ({
          ...state,
          isRefreshing: true,
        }),
      })
      axios
        .get('/data/reload-content')
        .then(function () {
          window.location.reload(false)
        })
        .catch(function () {
          dispatch({
            type: 'Refresh Done',
            path: [KEY_HEADER_NAV],
            reducer: (state) => ({
              ...state,
              isRefreshing: false,
            }),
          })
        })
    },
  }),
)(_HeaderNav)
