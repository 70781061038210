export const KEY_PROFILE_NAV = 'profileNav'

const getInitialState = () => ({
  creatingPDF: false,
})

export const setInitialState = (state) => {
  state[KEY_PROFILE_NAV] = getInitialState()
  return state
}
