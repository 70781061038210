import React from 'react'
import {Markdown} from '../Markdown'
import styles from './Highlights.module.scss'

export const Highlights = ({challenge, solution, deliverables, highlight, usedTechnologies}) => (
  <div className={styles.highlights}>
    <div className={styles.wrapper}>
      {challenge && (
        <div>
          <h3 className={styles.title}>Challenge</h3>
          <Markdown source={challenge} />
        </div>
      )}
      {solution && (
        <div>
          <h3 className={styles.title}>Solution</h3>
          <Markdown source={solution} />
        </div>
      )}
      {deliverables && (
        <div>
          <h3 className={styles.title}>Deliverables</h3>
          <Markdown source={deliverables} />
        </div>
      )}
      {highlight && (
        <div>
          <h3 className={styles.title}>Highlights</h3>
          <Markdown source={highlight} />
        </div>
      )}
      {usedTechnologies && (
        <div>
          <h3 className={styles.title}>Used Technologies</h3>
          <Markdown source={usedTechnologies} />
        </div>
      )}
    </div>
  </div>
)
