import React from 'react'
import styles from '../common.module.scss'
import {connect} from 'react-redux'
import DateRangePicker from '../DateRangePicker'
import CustomReactSelect from '../CustomReactSelect'
import SaveButton from '../SaveButton'
import MarkdownEditor from '../MarkdownEditor'
import {parseYear} from '../helpers'
import EnhanceWithAiButton, {promptTypes} from '../EnhanceWithAiButton'
class Education extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      education: props.education,
      addNewFaculty: false,
      newFaculty: '',
      addNewUniversity: false,
      newUniversity: '',
    }

    this.degreeOptions = props.degrees.map((degree) => ({label: degree.name, value: degree.name}))
    this.facultyOptions = props.faculties.map((faculty) => ({label: faculty.name, value: faculty.name}))
    this.universityOptions = props.universities.map((university) => ({label: university.name, value: university.name}))
  }

  handleUpdate = () => {
    if (this.props.onUpdate) {
      this.props.onUpdate({
        ...this.state.education,
        duration: `${this.state.education.from ? this.state.education.from.getFullYear() : ''} ${
          this.state.education.from && this.state.education.to ? ' - ' : ''
        } ${this.state.education.to ? this.state.education.to.getFullYear() : ''}`,
        from: this.state.education.from ? this.state.education.from.getFullYear() : '',
        to: this.state.education.to ? this.state.education.to.getFullYear() : '',
      })
    }
  }

  onEducationDescriptionChange(val) {
    this.setState({education: {...this.state.education, description: val}}, this.handleUpdate)
  }

  render() {
    return (
      <article className={styles.experience}>
        <div className={styles.content}>
          <div className={`${styles.label} ${styles.required}`}>University</div>
          <div className={styles.width75}>
            <CustomReactSelect
              value={this.universityOptions.filter((opt) => opt.value === this.state.education.school.school)}
              options={this.universityOptions}
              isValid={this.state.education.school.school}
              onChange={(selectedOption) =>
                this.setState(
                  {
                    education: {
                      ...this.state.education,
                      school: {...this.state.education.school, school: selectedOption.value},
                    },
                  },
                  this.handleUpdate,
                )
              }
              placeholder="Select university"
            />
          </div>
          <br />
          {!this.state.addNewUniversity ? (
            <button className={styles.textButton} onClick={() => this.setState({addNewUniversity: true})}>
              Is your university not available as an option?
            </button>
          ) : (
            <>
              <div className={styles.description}>
                If your university is not available as an option, you can add it manually below. Make sure to correctly
                capitalize the letters.
              </div>
              <div>
                <div className={styles.inputContainer}>
                  <input
                    className={styles.input}
                    size="100"
                    name="university"
                    value={this.state.newUniversity}
                    placeholder="University"
                    onChange={(event) => this.setState({newUniversity: event.target.value})}
                  />
                </div>
                <SaveButton
                  successMessage="Your university was added successfully."
                  endpointUrl="/add-university"
                  payload={{university: this.state.newUniversity}}
                  onSuccess={(response) => {
                    this.props.updateUniversities(response.data.universities)
                    this.universityOptions = response.data.universities.map((university) => ({
                      label: university.name,
                      value: university.name,
                    }))
                    this.setState(
                      {
                        newUniversity: '',
                        addNewUniversity: false,
                        education: {
                          ...this.state.education,
                          school: {...this.state.education.school, school: response.data.newUniversity.name},
                        },
                      },
                      this.handleUpdate,
                    )
                  }}
                  label="Add & Set University"
                  progressLabel="Adding University..."
                />
              </div>
            </>
          )}
          <div className={`${styles.label} ${styles.required}`}>Faculty</div>
          <div className={styles.width75}>
            <CustomReactSelect
              value={this.facultyOptions.filter((opt) => opt.value === this.state.education.school.faculty)}
              options={this.facultyOptions}
              isValid={this.state.education.school.faculty}
              onChange={(selectedOption) =>
                this.setState(
                  {
                    education: {
                      ...this.state.education,
                      school: {...this.state.education.school, faculty: selectedOption.value},
                    },
                  },
                  this.handleUpdate,
                )
              }
              placeholder="Select faculty"
            />
          </div>
          <br />
          {!this.state.addNewFaculty ? (
            <button className={styles.textButton} onClick={() => this.setState({addNewFaculty: true})}>
              Is your faculty not available as an option?
            </button>
          ) : (
            <>
              <div className={styles.description}>
                If your faculty is not available as an option, you can add it manually below. Make sure to correctly
                capitalize the letters.
              </div>
              <div>
                <div className={styles.inputContainer}>
                  <input
                    className={styles.input}
                    size="100"
                    name="faculty"
                    value={this.state.newFaculty}
                    placeholder="Faculty"
                    onChange={(event) => this.setState({newFaculty: event.target.value})}
                  />
                </div>
                <SaveButton
                  successMessage="Your faculty was added successfully."
                  endpointUrl="/add-faculty"
                  payload={{faculty: this.state.newFaculty}}
                  onSuccess={(response) => {
                    this.props.updateFaculties(response.data.faculties)
                    this.facultyOptions = response.data.faculties.map((faculty) => ({
                      label: faculty.name,
                      value: faculty.name,
                    }))
                    this.setState(
                      {
                        newFaculty: '',
                        addNewFaculty: false,
                        education: {
                          ...this.state.education,
                          school: {...this.state.education.school, faculty: response.data.newFaculty.name},
                        },
                      },
                      this.handleUpdate,
                    )
                  }}
                  label="Add & Set Faculty"
                  progressLabel="Adding Faculty..."
                />
              </div>
            </>
          )}
          <div className={`${styles.label} ${styles.required}`}>Degree</div>
          <div className={styles.width75}>
            <CustomReactSelect
              value={this.degreeOptions.filter((opt) => opt.value === this.state.education.degree)}
              options={this.degreeOptions}
              isValid={this.state.education.degree}
              onChange={(selectedOption) =>
                this.setState({education: {...this.state.education, degree: selectedOption.value}}, this.handleUpdate)
              }
              placeholder="Select degree"
            />
          </div>
          <div className={`${styles.label} ${styles.required}`}>Field of Study</div>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              size="100"
              name="fieldOfStudy"
              value={this.state.education.fieldOfStudy}
              placeholder="Field of Study"
              onChange={(event) =>
                this.setState(
                  {education: {...this.state.education, fieldOfStudy: event.target.value}},
                  this.handleUpdate,
                )
              }
            />
          </div>
          <div className={`${styles.label} ${styles.required}`}>Duration</div>
          <div className={styles.inputContainer}>
            <DateRangePicker
              isYear
              dateFormat={'yyyy'}
              startDateDefaultValue={this.state.education.from}
              endDateDefaultValue={this.state.education.to}
              onChange={(from, to) => {
                this.setState({education: {...this.state.education, from, to}}, this.handleUpdate)
              }}
            />
          </div>
          <div className={`${styles.label}`}>Description</div>
          <div className={styles.description}>
            Optionally write some interesting facts about the education. (eg. the title of your thesis, specialization,
            accomplishments...)
          </div>
          <br />
          <MarkdownEditor
            value={this.state.education.description}
            onChange={(newValue) => this.onEducationDescriptionChange(newValue)}
          />
          <EnhanceWithAiButton
            originalText={this.state.education.description}
            promptType={promptTypes.accomplishmentDescription}
            onAccept={(newValue) => this.onEducationDescriptionChange(newValue)}
          />
          <br />
          <br />
        </div>
      </article>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateFaculties: (faculties) =>
      dispatch({
        type: 'Add new faculty',
        path: ['faculties'],
        reducer: (state) => faculties,
      }),
    updateUniversities: (universities) =>
      dispatch({
        type: 'Add new university',
        path: ['universities'],
        reducer: (state) => universities,
      }),
  }
}

export default connect(
  (state, {education}) => ({
    education: {
      ...education,
      from: parseYear(education.from),
      to: parseYear(education.to),
    },
    degrees: state.degrees,
    faculties: state.faculties,
    universities: state.universities,
  }),
  mapDispatchToProps,
)(Education)
