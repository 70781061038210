import React from 'react'
import Project from './Project'
import {BioSection} from './BioSection'

export const ProjectsBox = ({experience, id, title}) => (
  <BioSection title={title} id={id}>
    {experience.map(
      // Highlight prop needs to be remapped, to not be overriden by merged accessibleProjects props from redux state
      (
        {slug, contribution, duration, description, position, technologies, project, title, highlight: highlights},
        index,
      ) => (
        <Project
          slug={slug}
          title={title}
          description={description}
          project={project}
          contribution={contribution}
          duration={duration}
          position={position}
          technologies={technologies}
          highlights={highlights}
          key={index}
        />
      ),
    )}
  </BioSection>
)
