import React from 'react'
import Select from 'react-select'

class CustomReactSelect extends React.Component {
  render() {
    const {isValid} = this.props
    const customStyles = {
      control: (base, state) => ({
        ...base,
        // state.isFocused can display different borderColor if you need it
        borderColor: state.isFocused ? '#ddd' : 'red',
        // overwrittes hover style
        '&:hover': {
          borderColor: state.isFocused ? '#ddd' : 'red',
        },
      }),
    }
    const defaultStyles = {}
    return <Select styles={isValid ? defaultStyles : customStyles} {...this.props} />
  }
}

export default CustomReactSelect
