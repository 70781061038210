import React from 'react'
import {PriorityExperience} from './PriorityExperience'
import {BioSection} from './BioSection'

export const PriorityExperienceBox = ({name, experience, id}) => (
  <BioSection title={name} id={id}>
    {experience.map(({highlight: highlights, ...exp}, index) => (
      <PriorityExperience highlights={highlights} {...exp} key={index} />
    ))}
  </BioSection>
)
