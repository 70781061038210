import React from 'react'
import {Provider} from 'react-redux'
import configureStore from './configureStore'
import App from './App'
import './assets/scss/style.scss'
import {createRoot} from 'react-dom/client'

const store = configureStore({
  initialState: JSON.parse(window.__REDUX_STATE__),
})

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
)
