import React from 'react'
import {Layout} from './components/Layout'
import Category from './components/Category'

const People = () => (
  <Layout title="Vacuumpeople - People" needsContent>
    <Category category="people" />
  </Layout>
)

export default People
