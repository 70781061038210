import React from 'react'
import classNames from 'classnames/bind'
import styles from './Select.module.scss'

const Option = ({id, title, selected, handleSelect}) => (
  <button className={classNames(styles.option, 'filterControl', {[styles.active]: selected})} onClick={handleSelect}>
    {title}
  </button>
)

const Options = ({options, selected, handleSelect}) => (
  <div className={styles.optionsBox}>
    <Option id="all" title="All" selected={selected.has('all')} handleSelect={() => handleSelect('all')} />
    {options.map(({id, title}) => (
      <Option id={id} key={id} title={title} selected={selected.has(id)} handleSelect={() => handleSelect(id)} />
    ))}
  </div>
)

const SelectButton = ({title, icon, active, open}) => (
  <button
    className={classNames(styles.selectButton, 'filterControl', {[styles.selectButtonActive]: active})}
    onClick={open}
  >
    {title} &nbsp;
    <i className={icon} aria-hidden="true" />
  </button>
)

export const Select = ({filterId, title, options, selected, handleSelect, opened, toggle}) => (
  <div className={styles.select}>
    <SelectButton
      title={title}
      icon={opened ? 'fa fa-angle-up' : 'fa fa-angle-down'}
      active={selected && selected.size > 0}
      open={toggle}
    />
    {opened && (
      <Options
        options={options}
        selected={selected}
        handleSelect={(optionId) => handleSelect({[filterId]: optionId})}
      />
    )}
  </div>
)
