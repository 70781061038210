import React from 'react'
import {Markdown} from '../Markdown'
import styles from './Education.module.scss'

export const Education = ({title, fieldOfStudy, duration, description}) => (
  <article className={styles.education}>
    <div className={styles.content}>
      <div className={styles.name}>
        <h3 className={styles.title}>{title}</h3>
      </div>
      <div className={styles.detail}>
        {fieldOfStudy && <span className={styles.fieldOfStudy}>{fieldOfStudy}</span>}
        <span>{duration}</span>
      </div>
      {description && <Markdown className={styles.description} source={description} />}
    </div>
  </article>
)
