import React from 'react'
import styles from '../common.module.scss'
import {connect} from 'react-redux'
import MarkdownEditor from '../MarkdownEditor'
import EnhanceWithAiButton, {promptTypes} from '../EnhanceWithAiButton'

class Bio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bio: props.bio,
    }
  }

  handleUpdate = () => {
    if (this.props.onUpdate) {
      this.props.onUpdate(this.state.bio)
    }
  }

  render() {
    return (
      <article>
        <br />
        <div className={styles.content}>
          <MarkdownEditor value={this.state.bio} onChange={(val) => this.setState({bio: val}, this.handleUpdate)} />
          <EnhanceWithAiButton
            originalText={this.state.bio}
            promptType={promptTypes.bio}
            onAccept={(val) => this.setState({bio: val}, this.handleUpdate)}
          />
        </div>
        <br />
      </article>
    )
  }
}

export default connect((state, {bio}) => ({
  bio: bio ?? '',
}))(Bio)
