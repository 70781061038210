import React from 'react'
import {Markdown} from '../Markdown'
import {Link} from 'react-router-dom'
import {ProjectContribution} from './ProjectContribution'
import {parseDuration, parseUrl} from '../helpers'
import styles from './Experience.module.scss'
import {connect} from 'react-redux'
import {ProjectType} from '../../consts/project'

const Project = ({
  title,
  startDate,
  endDate,
  slug,
  url,
  description,
  contribution,
  duration,
  position,
  technologies,
  highlights,
  project,
  usedTechnologies: projectTechnologies,
}) => {
  const descriptionText = description ?? project?.description
  const projectTitle = title ?? project?.title
  const projectUrl = url ?? project?.url
  return (
    <article className={styles.experience}>
      <div className={styles.content}>
        {projectTitle && (
          <div className={styles.name}>
            <h3 className={styles.title}>
              <Link to={`/projects/${slug}`}>{projectTitle}</Link>
            </h3>
          </div>
        )}
        {(position || duration || parseDuration(startDate, endDate)) && (
          <div className={styles.detail}>
            {position && <span className={styles.position}>{position}</span>}
            {(duration || parseDuration(startDate, endDate)) && (
              <span className={styles.date}>{duration || parseDuration(startDate, endDate)}</span>
            )}
          </div>
        )}
      </div>

      {descriptionText && <Markdown className={styles.description} source={descriptionText} />}

      {projectUrl && project.type !== ProjectType.INTERNAL && (
        <div className={styles.link}>
          <a href={projectUrl} target="_blank" rel="noopener noreferrer" className={styles.linkUrl}>
            {parseUrl(projectUrl)}
          </a>
        </div>
      )}

      <ProjectContribution
        contribution={contribution}
        highlights={highlights}
        technologies={technologies}
        projectTechnologies={projectTechnologies}
      />
    </article>
  )
}

export default connect((state, {slug}) => ({
  ...state.accessibleProjects?.[slug],
}))(Project)
