import React from 'react'
import {Link} from 'react-router-dom'
import styles from './Breadcrumbs.module.scss'

export const Breadcrumbs = ({category, categorySlug, lastItem}) => (
  <div className={styles.breadcrumbs}>
    <Link to={categorySlug} className={styles.breadcrumb}>
      {category}
    </Link>
    <span className={styles.breadcrumb}>{lastItem}</span>
  </div>
)
