import React from 'react'
import {apiRequest} from '../../network'
import {connect} from 'react-redux'
import {KEY_PROFILE_NAV} from '../../state/profileNav'
import styles from './PDFButton.module.scss'
import classnames from 'classnames'

class _PDFButton extends React.Component {
  state = {dropdownOpen: false}

  getPDF(style) {
    const {cvId, setCreatingPDF} = this.props
    setCreatingPDF(true)
    apiRequest(`/pdf/build/${style}/${cvId}`).then((response) => {
      if (response.path) {
        document.getElementById('pdf-downloader').src = response.path
      } else {
        alert('Error while creating PDF.') //eslint-disable-line no-alert
      }

      this.props.setCreatingPDF(false)
    })
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleEvent, false)
    document.addEventListener('scroll', this.handleEvent, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleEvent, false)
    document.removeEventListener('scroll', this.handleEvent, false)
  }

  handleEvent = (e) => {
    if (!this.node.contains(e.target)) {
      this.setState({dropdownOpen: false})
    }
  }

  render() {
    return (
      <div
        className={styles.dropdownHolder}
        ref={(node) => {
          this.node = node
        }}
      >
        <div className={styles.splitButtonHolder}>
          <button
            className={classnames(styles.button, styles.splitButtonLeft, styles[this.props.parent])}
            onClick={(e) => {
              e.preventDefault()
              this.getPDF('screen')
            }}
          >
            {this.props.creatingPDF ? 'Creating PDF ...' : 'Download as PDF'}
          </button>
          <button
            className={classnames(styles.button, styles.splitButtonRight, styles[this.props.parent])}
            onClick={(e) => {
              e.preventDefault()
              this.setState({dropdownOpen: !this.state.dropdownOpen})
            }}
          >
            &#9660;
          </button>
        </div>
        {this.state.dropdownOpen && (
          <ul className={classnames(styles.dropdown, styles.open)}>
            <button
              className={styles.dropdownButton}
              onClick={(e) => {
                e.preventDefault()
                this.getPDF('print')
              }}
            >
              Printable version
            </button>
          </ul>
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setCreatingPDF: (creatingPDF) =>
      dispatch({
        type: 'Set creating PDF',
        path: [KEY_PROFILE_NAV],
        reducer: (state) => ({
          ...state,
          creatingPDF,
        }),
      }),
  }
}

export const PDFButton = connect((state) => state[KEY_PROFILE_NAV], mapDispatchToProps)(_PDFButton)
