import React from 'react'
import axios from 'axios'
import styles from './common.module.scss'
import toast from 'react-hot-toast'

class SaveButton extends React.Component {
  constructor(props) {
    super(props)
    this.labels = {
      default: this.props.label ? this.props.label : 'Save',
      inProgress: this.props.progressLabel ? this.props.progressLabel : 'Saving...',
    }

    this.state = {
      button: {
        label: this.labels.default,
        disabled: false,
      },
      message: {
        value: '',
        style: '',
      },
      invalidMessage: '',
    }

    this.errorStyles = {
      option: (provided, state) => ({
        ...provided,
        color: 'red',
      }),
    }
  }

  saveData = () => {
    const button = {
      label: this.labels.inProgress,
      disabled: true,
    }
    this.setState({
      ...this.state,
      button,
    })

    if (this.props.validate) {
      const {result, message} = this.props.validate()
      if (!result) {
        toast.error(message)
        this.setState({invalidMessage: message, button: {label: this.labels.default, disabled: false}})
        return
      } else {
        this.setState({invalidMessage: ''})
      }
    }

    this.setState({invalid: false})
    const self = this
    axios
      .post(self.props.endpointUrl, self.props.payload ?? {})
      .then(function (response) {
        if (self.props.successMessage) {
          toast.success(self.props.successMessage)
        }

        self.setState({button: {label: self.labels.default, disabled: false}})

        if (self.props.onSuccess) {
          self.props.onSuccess(response)
        }
      })
      .catch(function (error) {
        self.setState({button: {label: self.labels.default, disabled: false}})
        toast.error(self.props.errorMessage ? self.props.errorMessage : 'Server error!')
        console.log(error) // eslint-disable-line no-console
      })
  }

  render() {
    return (
      <div className={styles.saveButtonContainer}>
        <button
          className={styles.primaryButton}
          onClick={() => this.saveData()}
          disabled={this.state.button.disabled || this.props.disabled}
        >
          {this.state.button.label}
        </button>
        {this.state.invalidMessage && <div className={styles.error}>{this.state.invalidMessage}</div>}
      </div>
    )
  }
}

export default SaveButton
