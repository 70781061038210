import React from 'react'
import styles from './ProfileNav.module.scss'
import {ProfileSubmenu} from './ProfileSubmenu'
import {PDFButton} from './PDFButton'
import classNames from 'classnames'

export const ProfileNav = ({
  name,
  workExperience,
  previousExperience,
  priorityExperience,
  education,
  isVisible,
  cvId,
}) => (
  <div className={classNames(styles.navigation, isVisible ? styles.show : styles.hide)}>
    <div className={styles.container}>
      <span className={styles.name}>{name}</span>
      <div className={styles.wrapper}>
        <ProfileSubmenu
          workExperience={workExperience}
          previousExperience={previousExperience}
          priorityExperience={priorityExperience}
          education={education}
        />
        {!!cvId && <PDFButton cvId={cvId} parent="profilenavbutton" />}
      </div>
    </div>
  </div>
)
