import React from 'react'
import Collapsible from './Collapsible'

class CollapsibleGroup extends React.Component {
  render() {
    return (
      <div>
        {this.props.data.map((d, index) => {
          return (
            <Collapsible
              key={`${index}x${this.props.data.length}`}
              title={this.props.getTitleFromData(d, index)}
              content={this.props.getElementFromData(d, index)}
              isOpen={this.props.openIndices ? this.props.openIndices.has(index) : undefined}
              buttons={
                this.props.buttons
                  ? this.props.buttons.map((button) => ({...button, onClick: () => button.onClick(index)}))
                  : []
              }
            />
          )
        })}
      </div>
    )
  }
}

export default CollapsibleGroup
