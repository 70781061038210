import React from 'react'
import {connect} from 'react-redux'
import styles from '../common.module.scss'
import SaveButton from '../SaveButton'
import {ConfirmModal} from '../ConfirmModal'
import CollapsibleGroup from '../CollapsibleGroup'
import Education from './Education'
import toast from 'react-hot-toast'
import axios from 'axios'

class _EducationTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      education: props.education,
      openIndices: null,
      selectedItemIndex: null,
      showConfirmationWindow: false,
    }
  }

  componentWillUnmount() {
    this.props.updateEducation(this.props.accessible, this.props.user.username, this.state.education)
  }

  addNewEducation = () => {
    const newEducation = {
      id: null,
      duration: '',
      fieldOfStudy: '',
      degree: '',
      school: {
        school: '',
        faculty: '',
      },
      from: '',
      to: '',
      description: '',
    }
    this.setState({education: [newEducation, ...this.state.education], openIndices: new Set([0])})
  }

  removeEducation = (index) => {
    this.setState({selectedItemIndex: index, showConfirmationWindow: true})
  }

  isEducationValid = (education) => {
    return education.university !== '' && education.faculty !== '' && education.degree !== ''
  }

  onUpdate = (index, data) => {
    this.setState({
      education: this.state.education
        .slice(0, index)
        .concat([data])
        .concat(this.state.education.slice(index + 1)),
    })

    if (this.props.onUpdate) {
      this.props.onUpdate()
    }
  }

  onSave = (education) => {
    this.props.updateEducation(this.props.accessible, this.props.user.username, education)
    this.setState({education})

    if (this.props.onSave) {
      this.props.onSave()
    }
  }

  handleConfirmationWindowResult = (result) => {
    if (result) {
      const education = this.state.education[this.state.selectedItemIndex]
      if (education.id !== null) {
        const self = this
        self.setState({showConfirmationWindow: false})
        axios
          .delete(`/education/${education.id}`)
          .then(function (response) {
            toast.success(`The education record was removed successfully.`)
            self.onSave(response.data.education)
            self.setState({
              selectedItemIndex: null,
              openIndices: null,
            })
          })
          .catch(function (error) {
            toast.error('Server error!')
            console.log(error) // eslint-disable-line no-console
            self.setState({selectedItemIndex: null})
          })
      } else {
        this.setState({
          selectedItemIndex: null,
          showConfirmationWindow: false,
          education: this.state.education
            .slice(0, this.state.selectedItemIndex)
            .concat(this.state.education.slice(this.state.selectedItemIndex + 1)),
          openIndices: null,
        })
      }
    } else {
      this.setState({selectedItemIndex: null, showConfirmationWindow: false})
    }
  }

  isValid = (edu) => {
    return (
      edu.school.school !== '' &&
      edu.school.faculty !== '' &&
      edu.degree !== '' &&
      edu.fieldOfStudy !== '' &&
      (edu.from !== '' || edu.to !== '')
    )
  }

  getEducationTitle = (edu) => {
    return `${edu.school.school ? edu.school.school : 'New Education'} ${edu.degree ? `- ${edu.degree}` : ''}`
  }

  validateEntries = () => {
    const invalidEntries = []
    this.state.education.forEach((edu) => {
      if (!this.isValid(edu)) {
        invalidEntries.push(this.getEducationTitle(edu))
      }
    })

    const invalidMessage = invalidEntries ? `Mandatory fields missing: ${invalidEntries.join(', ')}` : ''

    return {result: invalidEntries.length === 0, message: invalidMessage}
  }

  render() {
    return (
      <>
        <h3 className={styles.heading}>Education</h3>
        <div className={styles.headingWithButtonContainer}>
          <div className={styles.description}>
            State here your educational attainment and accomplishments connected with it.
          </div>
          <button className={styles.primaryButton} onClick={this.addNewEducation}>
            Add Education
          </button>
        </div>
        <CollapsibleGroup
          data={this.state.education}
          getElementFromData={(edu, index) => (
            <Education education={edu} onUpdate={(data) => this.onUpdate(index, data)} />
          )}
          getTitleFromData={(edu) => this.getEducationTitle(edu)}
          openIndices={this.state.openIndices}
          buttons={[{title: 'Remove', onClick: (index) => this.removeEducation(index)}]}
        />
        <div className={styles.gap32} />
        <SaveButton
          successMessage="Your profile was updated successfully."
          endpointUrl="/education-update"
          payload={{education: this.state.education}}
          onSuccess={(response) => this.onSave(response.data.education)}
          validate={this.validateEntries}
        />
        <div className={styles.gap320} />
        {this.state.showConfirmationWindow && (
          <ConfirmModal
            handleResult={this.handleConfirmationWindowResult}
            confirmButtonText="Remove"
            title="Remove Education"
            message={`Are you sure you want to remove your education record "${
              this.state.education[this.state.selectedItemIndex].school.school === '' &&
              this.state.education[this.state.selectedItemIndex].degree === ''
                ? 'New Education'
                : `${this.state.education[this.state.selectedItemIndex].school.school} - ${
                    this.state.education[this.state.selectedItemIndex].degree
                  }`
            }"? This cannot be reversed!`}
          />
        )}
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateEducation: (accessible, jiraID, education) =>
      dispatch({
        type: 'Update Person Education',
        path: [accessible ? 'accessiblePeople' : 'offboardedPeople', jiraID],
        reducer: (state) => ({
          ...state,
          education,
        }),
      }),
  }
}

export default connect((state) => {
  const isPersonOffboarded = !!state.offboardedPeople[state.user.username]
  const person = isPersonOffboarded
    ? state.offboardedPeople[state.user.username]
    : state.accessiblePeople[state.user.username]

  return {
    accessible: !isPersonOffboarded,
    user: state.user,
    education: person?.education ?? [],
  }
}, mapDispatchToProps)(_EducationTab)
