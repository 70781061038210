import React from 'react'
import {About} from './components/person/About'
import {ProjectsBox} from './components/person/ProjectsBox'
import {EducationBox} from './components/person/EducationBox'
import {AccomplishmentsBox} from './components/person/AccomplishmentsBox'
import SkillsBox from './components/person/SkillsBox'

import {Header} from './components/person/Header'
import {connect} from 'react-redux'
import styles from './CV.module.scss'
import layoutStyles from './components/Layout.module.scss'
import {ProfileNav} from './components/person/ProfileNav'
import {ProfileSubmenu} from './components/person/ProfileSubmenu'
import {KEY_PERSON} from './state/person'
import {isEmpty, sortBy} from 'lodash'
import logoImage from './assets/img/logo.svg'
import {PriorityExperienceBox} from './components/person/PriorityExperienceBox'
import {apiRequest} from './network'
import {Loading} from './Loading'
import {parseDateShort} from './components/helpers'

class CV extends React.Component {
  constructor(props) {
    super(props)
    this.onScroll = this.onScroll.bind(this)
  }

  onScroll = function () {
    const {toggleNavigation, isVisible} = this.props
    const pagePosition = window.pageYOffset
    const navScrollBreakpoint = 410

    if (pagePosition > navScrollBreakpoint && !isVisible) {
      toggleNavigation(true)
    }

    if (pagePosition <= navScrollBreakpoint && isVisible) {
      toggleNavigation(false)
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.onScroll, false)
    this.props.loadData(this.props.match.params.hash)
  }

  componentWillUnmount() {
    const {toggleNavigation} = this.props

    window.removeEventListener('scroll', this.onScroll, false)
    toggleNavigation(false)
  }

  render() {
    const {data, isVisible} = this.props

    if (!data?.id) {
      return <Loading />
    }

    const priorityExperience = data.workExperience
      .filter((experience) => experience.priority)
      .concat(data.previousExperience.filter((experience) => !!experience.priority))
      .sort((a, b) => b.priority - a.priority)

    const workExperience = data.workExperience.filter((experience) => !experience.priority)
    const previousExperience = data.previousExperience.filter((experience) => !experience.priority)
    const experience = sortBy(workExperience.concat(previousExperience), (experience) =>
      parseDateShort(experience.startDate),
    ).reverse()

    const hasPriorityExperience = !isEmpty(priorityExperience)

    return (
      <div className={layoutStyles.wrapper}>
        <ProfileNav
          name={data.name}
          priorityExperience={priorityExperience}
          workExperience={workExperience}
          previousExperience={previousExperience}
          education={data.education}
          isVisible={isVisible}
          cvId={data.cvId}
        />
        <div className={styles.header}>
          <img src={logoImage} alt="vacuumlabs" />
          <span className={styles.motto}>We put the Tech in FinTech</span>
        </div>
        <Header name={data.name} position={data.position} image={data.photo} cvId={data.cvId} hideCVButton />
        <div className={styles.wrapper}>
          <div className={styles.leftSide}>
            <About
              contract={data.contract}
              location={data.location}
              bio={data.bio}
              gitHubProfile={data.gitHubProfile}
              slackUserId={data.slackUserId}
            />
            {(data.technicalSkills || data.languageSkills) && (
              <SkillsBox technicalSkills={data.technicalSkills} languageSkills={data.languageSkills} />
            )}
            {!isEmpty(data.accomplishments) && <AccomplishmentsBox accomplishments={data.accomplishments} />}
          </div>
          <div className={styles.rightSide}>
            <ProfileSubmenu
              priorityExperience={priorityExperience}
              workExperience={workExperience}
              previousExperience={previousExperience}
              education={data.education}
            />
            {hasPriorityExperience && (
              <PriorityExperienceBox id="priorityExperience" experience={priorityExperience} name="My Experience" />
            )}
            {!isEmpty(experience) && (
              <ProjectsBox
                id="projects"
                experience={experience}
                title={hasPriorityExperience ? 'Other Experience' : 'My Experience'}
              />
            )}
            {!isEmpty(data.education) && <EducationBox id="education" education={data.education} />}
            {data.hash && (
              <div id="person-hash" style={{display: 'none'}}>
                {data.hash}
              </div>
            )}
          </div>
        </div>
        <iframe id="pdf-downloader" style={{display: 'none'}} title="pdf downloader" />
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleNavigation: (isVisible) =>
      dispatch({
        type: 'Show profile navigation',
        path: [KEY_PERSON],
        reducer: (state) => ({
          ...state,
          isVisible,
        }),
      }),
    loadData: (id) => {
      return apiRequest(`/person/${id}`)
        .then((response) => {
          if (response.status >= 400) {
            window.location.replace('/404')
          }
          dispatch({
            type: 'Load person',
            path: [KEY_PERSON],
            reducer: (state) => ({
              ...state,
              data: response,
            }),
          })
        })
        .catch((e) => {
          window.location.replace('/404')
        })
    },
  }
}

export default connect(
  (state) => ({
    ...state[KEY_PERSON],
  }),
  mapDispatchToProps,
)(CV)
