import React from 'react'
import {Layout} from './components/Layout'
import Category from './components/Category'

const Projects = () => (
  <Layout title="Vacuumpeople - Projects" needsContent>
    <Category category="projects" />
  </Layout>
)

export default Projects
