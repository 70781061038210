import React from 'react'
import {BioSection} from './BioSection'
import {Education} from './Education'

export const EducationBox = ({education, id}) => (
  <BioSection title="My education" id={id}>
    {education.map((education, index) => (
      <Education
        title={`${education.school.school}${education.school.faculty ? ` - ${education.school.faculty}` : ''}`}
        description={education.description}
        duration={education.duration}
        fieldOfStudy={`${education.degree} - ${education.fieldOfStudy}`}
        key={index}
      />
    ))}
  </BioSection>
)
