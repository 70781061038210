import React from 'react'
import {Markdown} from '../Markdown'
import styles from './ProjectContribution.module.scss'

export const ProjectContribution = ({contribution, highlights, technologies, projectTechnologies}) =>
  contribution || highlights || technologies || projectTechnologies ? (
    <div className={styles.contribution}>
      {contribution && (
        <div className={styles.content}>
          <div className={styles.name}>
            <h4 className={styles.title}>My contribution</h4>
          </div>
          <div className={styles.description}>
            <Markdown source={contribution} />
          </div>
        </div>
      )}
      {highlights && (
        <div className={styles.content}>
          <div className={styles.name}>
            <h4 className={styles.title}>Highlights</h4>
          </div>
          <div className={styles.description}>
            <Markdown source={highlights} />
          </div>
        </div>
      )}
      {technologies && (
        <div className={styles.content}>
          <div className={styles.name}>
            <h4 className={styles.title}>Used technologies</h4>
          </div>
          <div className={styles.description}>
            <Markdown source={technologies} />
          </div>
        </div>
      )}
      {!technologies && projectTechnologies && (
        <div className={styles.content}>
          <div className={styles.name}>
            <h4 className={styles.title}>Project technologies</h4>
          </div>
          <div className={styles.description}>
            <Markdown source={projectTechnologies} />
          </div>
        </div>
      )}
    </div>
  ) : null
