import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

const PrivateRoute = ({
  component: Component,
  authed,
  user,
  proofreader = false,
  admin = false,
  allowLinkToken = false,
  ...rest
}) => {
  if (!authed) {
    return <Route {...rest} render={(props) => <Redirect to="/login" />} />
  }

  if (user.linkToken && !allowLinkToken) {
    return <Route {...rest} render={(props) => <Redirect to="/profile" />} />
  }

  if ((proofreader && !user.proofreader) || (admin && !user.admin)) {
    return <Route {...rest} render={(props) => <Redirect to="/403" />} />
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />
}

export default connect((state) => ({
  user: state.user,
  authed: state.loggedIn,
}))(PrivateRoute)
