import React from 'react'
import styles from '../common.module.scss'
import {connect} from 'react-redux'
import CustomReactSelect from '../CustomReactSelect'
import DateRangePicker from '../DateRangePicker'
import MarkdownEditor from '../MarkdownEditor'
import {formatDate, parseDuration, parseDateShort} from '../helpers'
import EnhanceWithAiButton, {promptTypes} from '../EnhanceWithAiButton'

class Project extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      project: props.project,
    }
    this.projectOptions = Object.keys(this.props.accessibleProjects)
      .map((key, index) => ({
        label: this.props.accessibleProjects[key].projectName,
        value: this.props.accessibleProjects[key].slug,
      }))
      .sort((a, b) => (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1))
  }

  handleUpdate = () => {
    if (this.props.onProjectUpdate) {
      const updatedProject = {
        id: this.state.project.id,
        contribution: this.state.project.contribution,
        duration: parseDuration(this.state.project.startDate, this.state.project.endDate),
        startDate: formatDate(this.state.project.startDate),
        endDate: formatDate(this.state.project.endDate),
        highlight: this.state.project.highlight,
        position: this.state.project.position,
        technologies: this.state.project.technologiesArray.join(', '),
      }
      if (this.props.isVacuum) {
        this.props.onProjectUpdate({
          ...updatedProject,
          slug: this.state.project.slug,
          project: this.state.project.project,
        })
      } else {
        this.props.onProjectUpdate({
          ...updatedProject,
          title: this.state.project.title,
          description: this.state.project.description,
        })
      }
    }
  }

  handleEditTechnology(technology, index) {
    this.setState(
      {
        project: {
          ...this.state.project,
          technologiesArray: this.state.project.technologiesArray
            .slice(0, index)
            .concat([technology])
            .concat(this.state.project.technologiesArray.slice(index + 1)),
        },
      },
      this.handleUpdate,
    )
  }

  addTechnology(index) {
    this.setState(
      {
        project: {
          ...this.state.project,
          technologiesArray: this.state.project.technologiesArray.slice(0, index).concat(['']),
        },
      },
      this.handleUpdate,
    )
  }

  removeTechnology(index) {
    this.setState(
      {
        project: {
          ...this.state.project,
          technologiesArray:
            this.state.project.technologiesArray.length > 1
              ? this.state.project.technologiesArray
                  .slice(0, index)
                  .concat(this.state.project.technologiesArray.slice(index + 1))
              : [''],
        },
      },
      this.handleUpdate,
    )
  }

  onContributionChange(val) {
    this.setState({project: {...this.state.project, contribution: val}}, this.handleUpdate)
  }

  onHighlightChange(val) {
    this.setState({project: {...this.state.project, highlight: val}}, this.handleUpdate)
  }

  onDescriptionChange(val) {
    this.setState({project: {...this.state.project, description: val}}, this.handleUpdate)
  }

  render() {
    return (
      <article className={styles.experience}>
        <div className={styles.content}>
          {/* Project name */}
          <div className={`${styles.label} ${styles.required}`}>Project</div>
          {this.props.isVacuum ? (
            <div className={styles.width75}>
              <CustomReactSelect
                value={this.projectOptions.filter((opt) => opt.value === this.state.project.slug)}
                options={this.projectOptions}
                isValid={this.props.accessibleProjects[this.state.project.slug]}
                onChange={(selectedOption) =>
                  this.setState(
                    {
                      project: {
                        ...this.state.project,
                        slug: selectedOption.value,
                        project: this.props.accessibleProjects[selectedOption.value],
                      },
                    },
                    this.handleUpdate,
                  )
                }
                placeholder="Select project"
              />
            </div>
          ) : (
            <div className={styles.inputContainer}>
              <input
                className={styles.input}
                size="100"
                name="project"
                value={this.state.project.title}
                placeholder="Project"
                onChange={(event) =>
                  this.setState({project: {...this.state.project, title: event.target.value}}, this.handleUpdate)
                }
              />
            </div>
          )}

          {/* Project description */}
          {!this.props.isVacuum && (
            <>
              <div className={`${styles.label} ${styles.required}`}>Description</div>
              <div className={styles.description}>Write down a short project description.</div>
              <br />
              <MarkdownEditor
                value={this.state.project.description}
                onChange={(newValue) => this.onDescriptionChange(newValue)}
              />
              <EnhanceWithAiButton
                originalText={this.state.project.description}
                promptType={promptTypes.projectDescription}
                onAccept={(newValue) => this.onDescriptionChange(newValue)}
              />
            </>
          )}

          {/* Work duration */}
          <div className={`${styles.label} ${styles.required}`}>Work Duration</div>
          <div className={styles.description}>
            Leave the second field empty if you are still working on the project.
          </div>
          <div className={styles.inputContainer}>
            <DateRangePicker
              startDateDefaultValue={this.state.project.startDate}
              endDateDefaultValue={this.state.project.endDate}
              onChange={(startDate, endDate) => {
                this.setState({project: {...this.state.project, startDate, endDate}}, this.handleUpdate)
              }}
            />
          </div>

          {/* Work position */}
          <div className={`${styles.label} ${styles.required}`}>Work Position</div>
          <div className={styles.description}>
            One of the following (BFF stands for one of Backend/Frontend/Full-Stack)
            <ul>
              <li>BFF Engineer</li>
              <li>Senior BFF Engineer</li>
              <li>Senior BFF Engineer and Teamleader</li>
              <li>Senior QA Engineer (= tester writing automated tests)</li>
              <li>Tester (= manual testing)</li>
            </ul>
            <div className={styles.description}>
              The categories are not set in stone, feel free to bend them to your needs.
            </div>
          </div>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              size="100"
              name="position"
              value={this.state.project.position}
              placeholder="Work Position"
              onChange={(event) =>
                this.setState({project: {...this.state.project, position: event.target.value}}, this.handleUpdate)
              }
            />
          </div>

          {/* My contribution */}
          <div className={`${styles.label} ${styles.required}`}>My Contribution</div>
          <div className={styles.description}>
            Write something about your contribution to the project. Assume that the reader already knows what the
            project is about, so you can skip info such as "Kiwi sells flight tickets and no, don't believe the haters,
            it's not a scam". However, if you are part of a specific team on Kiwi, explain what the team does.
          </div>
          <br />
          <MarkdownEditor
            value={this.state.project.contribution}
            onChange={(newValue) => this.onContributionChange(newValue)}
          />
          <EnhanceWithAiButton
            originalText={this.state.project.contribution}
            promptType={promptTypes.projectContribution}
            onAccept={(newValue) => this.onContributionChange(newValue)}
          />
          <br />

          {/* Highlights */}
          <div className={styles.label}>Highlights</div>
          <div className={styles.description}>Something you are proud of or just something nice about the project.</div>
          <br />
          <MarkdownEditor
            value={this.state.project.highlight}
            onChange={(newValue) => this.onHighlightChange(newValue)}
          />
          <EnhanceWithAiButton
            originalText={this.state.project.highlight}
            promptType={promptTypes.projectHighlights}
            onAccept={(newValue) => this.onHighlightChange(newValue)}
          />
          <br />

          {/* Used technologies */}
          <div className={`${styles.label} ${this.props.isVacuum ? styles.required : ''}`}>Used Technologies</div>
          <div className={styles.description}>
            Mention most relevant tech (languages, databases, integrations, SDKs, frameworks, libraries, infrastructure
            stuff, cloud buzzwords) you personally used on the project.
          </div>
          <table className={`table ${styles.leftAlignedHeader} ${styles.tableContainer}`}>
            <tbody>
              {this.state.project.technologiesArray.map((technology, index) => (
                <tr key={`${index}x${technology}`}>
                  <td className={styles.tableCell}>
                    <CustomReactSelect
                      value={this.props.skillOptions.filter((obj) => obj.value === technology)}
                      options={this.props.skillOptions}
                      isValid={this.props.isVacuum ? this.props.skillsMap[technology] : true}
                      onChange={(selectedOption) => this.handleEditTechnology(selectedOption.value, index)}
                      placeholder="Technology"
                    />
                  </td>
                  <td>
                    <button className={styles.addButton} onClick={() => this.removeTechnology(index)}>
                      -
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <th width="100%">
                  <button className={styles.secondaryButton} onClick={() => this.addTechnology()}>
                    Add Tech
                  </button>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
    )
  }
}

export default connect((state, {project, isVacuum}) => ({
  isVacuum,
  project: {
    id: project.id,
    slug: project.slug ?? '',
    project: project.project,
    title: project.title ?? '',
    description: project.description ?? '',
    contribution: project.contribution ?? '',
    highlight: project.highlight ?? '',
    position: project.position ?? '',
    technologies: project.technologies ?? '',
    technologiesArray: project.technologies ? project.technologies.split(', ') : [''],
    startDate: parseDateShort(project.startDate),
    endDate: parseDateShort(project.endDate),
    proofread: project.proofread,
  },
  accessibleProjects: state.accessibleProjects,
  skillOptions: state.skills.map((skill) => ({label: skill.name, value: skill.name})),
  skillsMap: state.skillsMap,
}))(Project)
