import React from 'react'
import styles from '../common.module.scss'
import {connect} from 'react-redux'
import MarkdownEditor from '../MarkdownEditor'
import EnhanceWithAiButton, {promptTypes} from '../EnhanceWithAiButton'

class Accomplishment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accomplishment: props.accomplishment,
    }

    // This generates a random string used to create unique IDs for the quill editors
    this.randomIdModifier = (Math.random() + 1).toString(36).substring(7)
  }

  handleUpdate = () => {
    if (this.props.onUpdate) {
      this.props.onUpdate({
        id: this.state.accomplishment.id,
        title: this.state.accomplishment.title,
        description: this.state.accomplishment.description,
      })
    }
  }

  onDescriptionChange(val) {
    this.setState({accomplishment: {...this.state.accomplishment, description: val}}, this.handleUpdate)
  }

  render() {
    return (
      <article className={styles.experience}>
        <div className={styles.content}>
          <div className={`${styles.label} ${styles.required}`}>Headline</div>
          <div className={styles.description}>
            If you don't want to think of a headline for each accomplishment, you can instead use a generic headline
            like&nbsp; "<i>Achievements</i>" or "<i>Certificates</i>" and write multiple entries within a single record.
          </div>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              size="100"
              name="headline"
              value={this.state.accomplishment.title}
              placeholder="Headline"
              onChange={(event) =>
                this.setState(
                  {accomplishment: {...this.state.accomplishment, title: event.target.value}},
                  this.handleUpdate,
                )
              }
            />
          </div>
          <div className={`${styles.label} ${styles.required}`}>Description</div>
          <div className={styles.description}>Describe the accomplishment. Keep it short and simple.</div>
          <br />
          <MarkdownEditor
            value={this.state.accomplishment.description}
            onChange={(newVal) => this.onDescriptionChange(newVal)}
          />
          <EnhanceWithAiButton
            originalText={this.state.accomplishment.description}
            promptType={promptTypes.accomplishmentDescription}
            onAccept={(newVal) => this.onDescriptionChange(newVal)}
          />
          <br />
          <br />
        </div>
      </article>
    )
  }
}

export default connect((state, {accomplishment}) => ({
  accomplishment: {
    id: accomplishment.id ?? null,
    title: accomplishment.title ?? '',
    description: accomplishment.description ?? '',
    proofread: accomplishment.proofread ?? true,
  },
}))(Accomplishment)
