import React from 'react'
import styles from '../common.module.scss'
import {connect} from 'react-redux'
import MarkdownEditor from '../MarkdownEditor'
import EnhanceWithAiButton, {promptTypes} from '../EnhanceWithAiButton'

class Project extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      project: props.project,
    }
  }

  handleUpdate = () => {
    if (this.props.onProjectUpdate) {
      if (this.props.isVacuum) {
        this.props.onProjectUpdate({
          ...this.props.project,
          highlight: this.state.project.highlight,
          contribution: this.state.project.contribution,
        })
      } else {
        this.props.onProjectUpdate({
          ...this.props.project,
          description: this.state.project.description,
          highlight: this.state.project.highlight,
          contribution: this.state.project.contribution,
        })
      }
    }
  }

  render() {
    return (
      <article className={styles.experience}>
        <div className={styles.content}>
          {!this.props.isVacuum && (
            <>
              <div className={`${styles.label} ${styles.required}`}>Description</div>
              <MarkdownEditor
                value={this.state.project.description}
                onChange={(val) =>
                  this.setState({project: {...this.state.project, description: val}}, this.handleUpdate)
                }
              />
            </>
          )}
          <div className={`${styles.label} ${styles.required}`}>Contribution</div>
          <MarkdownEditor
            value={this.state.project.contribution}
            onChange={(val) => this.setState({project: {...this.state.project, contribution: val}}, this.handleUpdate)}
          />
          <EnhanceWithAiButton
            originalText={this.state.project.contribution}
            promptType={promptTypes.projectContribution}
            onAccept={(val) => this.setState({project: {...this.state.project, contribution: val}}, this.handleUpdate)}
          />
          <div className={styles.label}>Highlights</div>
          <MarkdownEditor
            value={this.state.project.highlight}
            onChange={(val) => this.setState({project: {...this.state.project, highlight: val}}, this.handleUpdate)}
          />
          <EnhanceWithAiButton
            originalText={this.state.project.highlight}
            promptType={promptTypes.projectHighlights}
            onAccept={(val) => this.setState({project: {...this.state.project, highlight: val}}, this.handleUpdate)}
          />
          <br />
          <br />
        </div>
      </article>
    )
  }
}

export default connect((state, {project, isVacuum}) => ({
  project: {
    ...project,
    description: project.description ?? '',
    contribution: project.contribution ?? '',
    highlight: project.highlight ?? '',
  },
}))(Project)
