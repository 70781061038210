import React from 'react'
import {connect} from 'react-redux'
import styles from '../common.module.scss'
import EnhanceWithAiButton, {promptTypes, uiStates} from '../EnhanceWithAiButton'
import MarkdownEditor from '../MarkdownEditor'
import SaveButton from '../SaveButton'
import CustomReactSelect from '../CustomReactSelect'
import techSkillsStyles from './TechSkillsTab.module.scss'

class _BioTab extends React.Component {
  constructor(props) {
    super(props)
    this.languageOptions = props.languages.map((language) => ({label: language.name, value: language.name}))
    this.languageExperienceOptions = ['Basic', 'Advanced', 'Expert'].map((value) => ({label: value, value}))
    this.state = {
      pref: props.pref,
      bio: props.bio,
      languageSkills: this.createLanguageSkillsState(props.languageSkills),
      aiButtonState: undefined,
    }
  }

  createLanguageSkillsState = (languageSkills) => {
    return languageSkills.map((lang) => ({
      label: lang.label,
      value: lang.value,
      isValidLabel: true,
      isValidValue: true,
    }))
  }

  componentWillUnmount() {
    this.props.updateBio(
      this.props.accessible,
      this.props.user.username,
      this.state.bio,
      this.state.languageSkills,
      this.state.pref,
      this.state.proofread,
    )
  }

  handlePrefChange = (evnt) => {
    const {name, value} = evnt.target
    this.setState({[name]: value})

    if (this.props.onUpdate) {
      this.props.onUpdate()
    }
  }

  updateBio = (val) => {
    this.setState({bio: val})

    if (this.props.onUpdate) {
      this.props.onUpdate()
    }
  }

  addLanguageRow = () => {
    const newRow = {
      label: '',
      value: '',
      isValidLabel: false,
      isValidValue: false,
    }
    this.setState({
      ...this.state,
      languageSkills: [...this.state.languageSkills, newRow],
    })
  }

  deleteLanguageRow = (index) => {
    this.state.languageSkills.splice(index, 1)
    this.validateLanguageSkills()
    this.setState(this.state)
  }

  validateLanguageSkills = () => {
    const languageOccurrences = {}
    for (const skill of this.state.languageSkills) {
      languageOccurrences[skill.label] = (languageOccurrences[skill.label] || 0) + 1
    }
    for (const skill of this.state.languageSkills) {
      skill.isValidLabel = languageOccurrences[skill.label] === 1 && skill.label !== ''
      skill.isValidValue = skill.value !== ''
    }
  }

  handleLanguageChange = (index, name, selectedOption) => {
    const skills = this.state.languageSkills
    skills[index][name] = selectedOption.value

    this.validateLanguageSkills()
    this.setState(this.state)

    if (this.props.onUpdate) {
      this.props.onUpdate()
    }
  }

  validateBeforeSave = () => {
    const languageOccurrences = {}
    for (const skill of this.state.languageSkills) {
      if (skill.label === '' || skill.value === '') {
        const errorMessage = `You haven't selected skill name or value.${skill.label ? ` (${skill.label})` : ''}`
        return {result: false, message: errorMessage}
      }
      if (skill.label in languageOccurrences) {
        const errorMessage = `You have selected duplicated languages. (${skill.label})`
        return {result: false, message: errorMessage}
      } else {
        languageOccurrences[skill.label] = 1
      }
    }
    return {result: true, message: ''}
  }

  onSave = (bio, languageSkills, pref, proofread) => {
    this.props.updateBio(this.props.accessible, this.props.user.username, bio, languageSkills, pref, proofread)
    const languageSkillsState = this.createLanguageSkillsState(languageSkills)
    this.setState({bio, languageSkills: languageSkillsState, pref, proofread})

    if (this.props.onSave) {
      this.props.onSave()
    }
  }

  render() {
    const shouldDisableBioEditor = this.state.aiButtonState !== uiStates.default
    return (
      <>
        <h3 className={styles.heading}>Bio</h3>
        <div className={styles.description}>
          Write a couple of sentences about yourself, your background and hobbies. The main point is to introduce
          yourself to a potential client. Avoid info which is already available in other CV sections, eg. name,
          position, school or projects. Try to avoid the time references such as x years ago, this year, etc. so that
          bio could be valid also in the next few years.
        </div>
        <br />
        <div className={styles.description}>
          <strong>Example:</strong> I enjoy creating simple and reliable web and mobile applications. My preference
          typically is to work on the frontend where I advocate using a functional approach for state management and
          also for the view layer. I enjoy working in React (and other new-era frameworks inspired by it) which
          pioneered using such practices for the FE. Apart from my FE experiences I've also co-created several
          middle-sized REST and GraphQL backend systems.
        </div>
        <br />
        <MarkdownEditor
          value={this.state.bio}
          onChange={(newText) => this.updateBio(newText)}
          readOnly={shouldDisableBioEditor}
        />
        <EnhanceWithAiButton
          originalText={this.state.bio}
          promptType={promptTypes.bio}
          onAccept={(newText) => this.updateBio(newText)}
        />
        <h3 className={styles.heading}>Spoken languages</h3>
        <div className={styles.description}>
          Please add all languages you speak and pick your level based on the definitions below. Formal education and
          professional experience can be good indicators for higher levels, but they are not strict requirements.
          <ul className={styles.list}>
            <li>
              <strong>Basic:</strong> I have a basic understanding and can engage in simple conversations.
            </li>
            <li>
              <strong>Advanced:</strong> I can communicate effectively in common situations and technical discussions in
              my field.
            </li>
            <li>
              <strong>Expert:</strong> I can easily engage with complex or novel topics without language-related issues.
            </li>
          </ul>
        </div>
        <table className={`table ${styles.leftAlignedHeader} ${techSkillsStyles.tableContainer}`}>
          <thead>
            <tr>
              <th className={techSkillsStyles.skillGroupTitle} width="65%">
                Spoken Languages
              </th>
              <th className={styles.skillGroupLevel} width="25%">
                Level
              </th>
              <th width="10%" />
            </tr>
          </thead>
          <tbody>
            {this.state.languageSkills.length > 0 &&
              this.state.languageSkills.map(({label, value, isValidLabel, isValidValue}, index) => (
                <tr key={index}>
                  <td className={styles.tableCell}>
                    <CustomReactSelect
                      value={this.languageOptions?.filter((obj) => obj.value === label)}
                      options={this.languageOptions}
                      isValid={isValidLabel}
                      onChange={(selectedOption) => this.handleLanguageChange(index, 'label', selectedOption)}
                      placeholder="Skill"
                    />
                  </td>
                  <td className={styles.tableCellLevel}>
                    <CustomReactSelect
                      value={this.languageExperienceOptions?.filter((obj) => obj.value === value)}
                      options={this.languageExperienceOptions}
                      isValid={isValidValue}
                      onChange={(selectedOption) => this.handleLanguageChange(index, 'value', selectedOption)}
                      placeholder="Level"
                    />
                  </td>
                  <td>
                    <button className={styles.addButton} onClick={() => this.deleteLanguageRow(index)}>
                      -
                    </button>
                  </td>
                </tr>
              ))}
            <tr>
              <th width="65%">
                <button className={styles.secondaryButton} onClick={() => this.addLanguageRow()}>
                  Add Language
                </button>
              </th>
            </tr>
          </tbody>
        </table>
        <h3 className={styles.heading}>Preferences</h3>
        <div className={styles.description}>
          Please tell us about your preferences.
          <ul>
            <li>Would you like to work mostly on FE / BE / Full-stack / Android / iOS?</li>
            <li>Is there any industry you prefer to work / not work in? (E.g. Crypto, Fintech)</li>
            <li>Is there any technology you would love to / hate to work with?</li>
            <li>Please keep it short and simple.</li>
          </ul>
          <div className={styles.description}>Example: FE. No crypto, please. I want to work with React.</div>
        </div>
        <br />
        <input
          className={styles.input}
          size="100"
          name="pref"
          value={this.state.pref}
          onChange={this.handlePrefChange}
        />
        <div className={styles.gap32} />
        <SaveButton
          validate={this.validateBeforeSave}
          successMessage="Your profile was updated successfully."
          endpointUrl="/bio-update"
          payload={{
            pref: this.state.pref,
            bio: this.state.bio,
            languageSkills: this.state.languageSkills.map(({label, value}) => ({label, value})),
          }}
          onSuccess={(response) =>
            this.onSave(response.data.bio, response.data.languageSkills, response.data.pref, response.data.proofread)
          }
        />
        <div className={styles.gap320} />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateBio: (accessible, jiraID, bio, languageSkills, pref, proofread) =>
      dispatch({
        type: 'Update Person Bio',
        path: [accessible ? 'accessiblePeople' : 'offboardedPeople', jiraID],
        reducer: (state) => ({
          ...state,
          bio,
          languageSkills,
          pref,
          proofread,
        }),
      }),
  }
}

export default connect((state) => {
  const isPersonOffboarded = !!state.offboardedPeople[state.user.username]
  const person = isPersonOffboarded
    ? state.offboardedPeople[state.user.username]
    : state.accessiblePeople[state.user.username]

  const pref = person?.pref
  const bio = person?.bio
  const languageSkills = person?.languageSkills ?? []
  const proofread = person?.proofread
  return {
    accessible: !isPersonOffboarded,
    user: state.user,
    pref,
    bio,
    languageSkills,
    languages: state.languages,
    proofread,
  }
}, mapDispatchToProps)(_BioTab)
