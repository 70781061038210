import React from 'react'
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import {Layout} from './components/Layout'
import {connect} from 'react-redux'

class LinkLogin extends React.Component {
  componentDidMount() {
    if (this.props.authed) {
      if (this.props.user && this.props.user.proofreader) {
        this.props.history.push('/proofread')
      }
      this.props.history.push('/profile')
    } else {
      if (this.props.match.params.token) {
        axios.get(`/vlsso/link-login?token=${this.props.match.params.token}`).then((res) => {
          window.location.href = '/profile'
        })
      } else {
        this.props.history.push('/login')
      }
    }
  }

  render() {
    return (
      <Layout title="Vacuumpeople">
        <div />
      </Layout>
    )
  }
}

export default withRouter(
  connect((state) => ({
    authed: state.loggedIn,
    user: state.user,
  }))(LinkLogin),
)
