import React from 'react'
import styles from './Login.module.scss'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {KEY_LOGIN, getInitialState} from '../state/login'
import {withRouter} from 'react-router-dom'
import queryString from 'query-string-es5'

class Login extends React.Component {
  componentDidMount() {
    this.props.resetState()
  }

  render() {
    const {loading} = this.props
    const {redirectTo} = queryString.parse(this.props.location.search)
    return (
      <div>
        <div className={styles.login}>
          <button
            onClick={() => {
              window.location.replace(`/vlsso/login?redirect=${redirectTo || '/'}`)
              this.props.setLoading(true)
            }}
            className={styles.button}
          >
            {loading ? 'Loading...' : 'VL SSO Login'}
          </button>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetState: () =>
      dispatch({
        type: 'Reset login state',
        path: [KEY_LOGIN],
        reducer: () => ({
          ...getInitialState(),
        }),
      }),
    setLoading: (loading) =>
      dispatch({
        type: 'Set loading',
        path: [KEY_LOGIN],
        reducer: (state) => ({
          ...state,
          loading,
        }),
      }),
  }
}

export default compose(
  connect((state) => state[KEY_LOGIN], mapDispatchToProps),
  withRouter,
)(Login)
