import React from 'react'
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom'
import People from './People'
import Organisation from './Organisation'
import Projects from './Projects'
import Person from './Person'
import Project from './Project'
import Auth from './Auth'
import {Error404, Error403} from './Error'
import PrivateRoute from './PrivateRoute'
import ProfileUpdate from './ProfileUpdate'
import Proofread from './Proofread'
import {Toaster} from 'react-hot-toast'
import UserManagement from './UserManagement'
import LinkLogin from './LinkLogin'
import CV from './CV'
import {PUBLIC_CV_PATH} from './consts/cv'

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/people" />} />
          <Route exact path="/login" component={Auth} />
          <Route exact path="/signon/:token" component={LinkLogin} />
          <Route exact path={`${PUBLIC_CV_PATH}/:hash`} component={CV} />
          <PrivateRoute exact path="/people" component={People} />
          <PrivateRoute exact path="/organisation" component={Organisation} />
          <PrivateRoute exact path="/projects" component={Projects} />
          <PrivateRoute exact path="/people/:slug" component={Person} />
          <PrivateRoute exact path="/profile" component={ProfileUpdate} allowLinkToken />
          <PrivateRoute exact path="/projects/:slug" component={Project} />
          <PrivateRoute exact path="/proofread" component={Proofread} proofreader allowLinkToken />
          <PrivateRoute exact path="/usermanagement" component={UserManagement} admin />
          <Route exact path="/404" component={Error404} />
          <Route exact path="/403" component={Error403} />
          <Redirect to="/404" />
        </Switch>
        <Toaster toastOptions={{duration: 4000}} />
      </Router>
    )
  }
}
