export const KEY_CATEGORY = 'category'

const getInitialCategoryState = () => ({
  filters: {ids: new Set(['all']), locations: new Set(['all'])},
})

const getInitialState = () => ({
  people: getInitialCategoryState(),
  projects: getInitialCategoryState(),
})

export const setInitialState = (state) => {
  state[KEY_CATEGORY] = getInitialState()
  return state
}
