import React from 'react'
import axios from 'axios'
import {concat, compact} from 'lodash'
import CustomReactSelect from '../CustomReactSelect'
import {connect} from 'react-redux'
import techSkillsStyles from './TechSkillsTab.module.scss'
import styles from '../common.module.scss'
import toast from 'react-hot-toast'

class _TechSkillsTab extends React.Component {
  constructor(props) {
    super(props)
    this.valueOptions = ['Basic', 'Advanced', 'Expert'].map((value) => ({label: value, value}))
    this.skillGroupsMap = props.skillGroups.reduce((map, skillGroup) => {
      skillGroup.skills.forEach((skill) => {
        map[skill.label] = skillGroup.type
      })
      return map
    }, {})
    const userSkillsMap = this.createUserSkillsMap(this.props.userSkills)
    this.state = {
      skillsMap: {...userSkillsMap},
      button: {
        label: 'Save',
        disabled: false,
      },
      message: {
        value: '',
        style: '',
      },
    }

    this.errorStyles = {
      option: (provided, state) => ({
        ...provided,
        color: 'red',
      }),
    }
  }

  componentWillUnmount() {
    this.props.updateTechnicalSkills(
      this.props.accessible,
      this.props.user.username,
      Object.values(this.state.skillsMap)
        .flat()
        .filter((skill) => skill.label),
    )
  }

  createUserSkillsMap(userSkills) {
    const userSkillsMap = {}
    this.props.skillGroups.forEach((skillGroup) => {
      userSkillsMap[skillGroup.type] = []
    })
    userSkills.forEach((userSkill) => {
      userSkillsMap[this.skillGroupsMap[userSkill.label]].push({
        ...userSkill,
        isValidLabel: true,
        isValidValue: true,
      })
    })
    return userSkillsMap
  }

  addTableRows = (skillGroupType) => {
    const rowsInput = {
      label: '',
      value: '',
      isValidLabel: false,
      isValidValue: false,
    }
    this.setState({
      ...this.state,
      skillsMap: {...this.state.skillsMap, [skillGroupType]: [...this.state.skillsMap[skillGroupType], rowsInput]},
    })
  }

  deleteTableRows = (skillGroupType, index) => {
    const skills = this.state.skillsMap[skillGroupType]
    skills.splice(index, 1)
    this.setState(this.state)
  }

  handleChange = (skillGroupType, index, name, selectedOption) => {
    const skills = this.state.skillsMap[skillGroupType]
    skills[index][name] = selectedOption.value
    if (name === 'label') {
      skills[index].isValidLabel = true
    } else {
      skills[index].isValidValue = true
    }
    const skillMap = {}
    const allSkills = this.state.skillsMap[skillGroupType]
    for (const skill of allSkills) {
      if (skill.label === '') {
        skill.isValidLabel = false
      } else {
        if (skill.label in skillMap) {
          skill.isValidLabel = false
          skillMap[skill.label].isValidLabel = false
          this.setState(this.state)
        } else {
          skillMap[skill.label] = skill
          skill.isValidLabel = true
        }
      }
    }
    this.setState(this.state)

    if (this.props.onUpdate) {
      this.props.onUpdate()
    }
  }

  saveTableRows = () => {
    const skillMap = {}
    const allSkills = concat(Object.values(this.state.skillsMap)).flat()
    for (const skill of allSkills) {
      if (skill.label === '' || skill.value === '') {
        const errorMessage = `You haven't selected skill name or value.${skill.label ? ` (${skill.label})` : ''}`
        const message = {
          value: errorMessage,
          style: styles.error,
        }
        this.setState({
          ...this.state,
          message,
        })
        toast.error(errorMessage)
        return
      }
      if (skill.label in skillMap) {
        const errorMessage = `You have selected duplicated skills. (${skill.label})`
        const message = {
          value: errorMessage,
          style: styles.error,
        }
        this.setState({
          ...this.state,
          message,
        })
        toast.error(errorMessage)
        return
      } else {
        skillMap[skill.label] = 1
      }
    }
    const button = {
      label: 'Saving...',
      disabled: true,
    }
    this.setState({
      ...this.state,
      button,
    })
    const self = this
    axios
      .post('/skills-update', {
        technicalSkills: allSkills,
      })
      .then(function (response) {
        const button = {
          label: 'Save',
          disabled: false,
        }
        toast.success('Your profile was updated successfully.')
        const message = {
          value: '',
          style: '',
        }
        const userSkills = response.data.technicalSkills
        const userSkillsMap = self.createUserSkillsMap(userSkills)
        self.setState({
          ...self.state,
          skillsMap: userSkillsMap,
          message,
          button,
        })
        self.props.updateTechnicalSkills(self.props.accessible, self.props.user.username, userSkills)
      })
      .catch(function (error) {
        const button = {
          label: 'Save',
          disabled: false,
        }
        toast.error('Server error!')
        const message = {
          value: '',
          style: '',
        }
        self.setState({
          ...self.state,
          message,
          button,
        })
        console.log(error) // eslint-disable-line no-console
      })

    if (this.props.onSave) {
      this.props.onSave()
    }
  }

  render() {
    return (
      <>
        <h3 className={styles.heading}>Technologies / expertise</h3>
        <div className={styles.description}>
          Please add all technologies which you have experience in and pick your level based on the definitions below.
          &nbsp;<strong>The level which you assess yourself should correspond to your project experience.</strong> E.g.
          if you state that you are an Expert or Advanced in Kotlin, it needs to be clearly visible in your CV that you
          used Kotlin on a project or on various projects for a relevant period of time. Working on a project where
          certain technology was used without really using the technology yourself does not count.
          <ul className={styles.list}>
            <li>
              <strong>Basic:</strong> I have worked with the technology during a small period of time. I have seen some
              happy path use cases.
            </li>
            <li>
              <strong>Advanced:</strong> I have used the technology at least on one project regularly and I understand
              more complex use cases.
            </li>
            <li>
              <strong>Expert:</strong> The technology was my main focus at least on one project, I know its weaknesses
              and strengths and I can compare it to its main competitors.
            </li>
          </ul>
        </div>
        {this.props.skillGroups.map((skillGroup, index) => (
          <div key={index}>
            <table className={`table ${styles.leftAlignedHeader} ${techSkillsStyles.tableContainer}`}>
              <thead>
                <tr>
                  <th className={techSkillsStyles.skillGroupTitle} width="65%">
                    {skillGroup.type}
                  </th>
                  <th className={styles.skillGroupLevel} width="25%">
                    Level
                  </th>
                  <th width="10%" />
                </tr>
              </thead>
              <tbody>
                {this.state.skillsMap[skillGroup.type].map(({label, value, isValidLabel, isValidValue}, index) => (
                  <tr key={index}>
                    <td className={styles.tableCell}>
                      <CustomReactSelect
                        value={skillGroup.skills.filter((obj) => obj.value === label)}
                        options={skillGroup.skills}
                        isValid={isValidLabel}
                        onChange={(selectedOption) =>
                          this.handleChange(skillGroup.type, index, 'label', selectedOption)
                        }
                        placeholder="Skill"
                      />
                    </td>
                    <td className={styles.tableCellLevel}>
                      <CustomReactSelect
                        value={this.valueOptions.filter((obj) => obj.value === value)}
                        options={this.valueOptions}
                        isValid={isValidValue}
                        onChange={(selectedOption) =>
                          this.handleChange(skillGroup.type, index, 'value', selectedOption)
                        }
                        placeholder="Level"
                      />
                    </td>
                    <td>
                      <button className={styles.addButton} onClick={() => this.deleteTableRows(skillGroup.type, index)}>
                        -
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th width="65%">
                    <button className={styles.secondaryButton} onClick={() => this.addTableRows(skillGroup.type)}>
                      Add Tech
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
        <div className={styles.gap32} />
        <div className={styles.saveButtonContainer}>
          <button
            className={styles.primaryButton}
            onClick={() => this.saveTableRows()}
            disabled={this.state.button.disabled}
          >
            {this.state.button.label}
          </button>
          {this.state.message.style && <div className={this.state.message.style}>{this.state.message.value}</div>}
        </div>
        <div className={styles.gap320} />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateTechnicalSkills: (accessible, jiraID, technicalSkills) =>
      dispatch({
        type: 'Update Person Skills',
        path: [accessible ? 'accessiblePeople' : 'offboardedPeople', jiraID],
        reducer: (state) => ({
          ...state,
          technicalSkills: [
            {
              skills: technicalSkills,
            },
          ],
        }),
      }),
  }
}

export default connect((state) => {
  const isPersonOffboarded = !!state.offboardedPeople[state.user.username]
  const person = isPersonOffboarded
    ? state.offboardedPeople[state.user.username]
    : state.accessiblePeople[state.user.username]

  const technicalSkills = compact(person?.technicalSkills?.reduce((acc, curr) => acc.concat(curr.skills), []))?.map(
    (skill) => {
      return {
        label: skill.label,
        value: skill.value,
      }
    },
  )

  const skillGroups = state.skillGroups.map((skillGroup) => ({
    ...skillGroup,
    skills: skillGroup.skills.map((skill) => ({label: skill.name, value: skill.name})),
  }))

  return {
    accessible: !isPersonOffboarded,
    userSkills: technicalSkills ?? [],
    skillGroups,
    user: state.user,
  }
}, mapDispatchToProps)(_TechSkillsTab)
