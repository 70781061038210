import React from 'react'
import styles from './Rating.module.scss'

export const Rating = ({value, skill}) => {
  return (
    <tr>
      <td className={styles.wrapper} width="75%">
        <span className={styles.name}>{skill} &nbsp;</span>
      </td>
      <td className={styles.stars} width="25%">
        <span className={styles.name}>{value}</span>
      </td>
    </tr>
  )
}
