import React from 'react'
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {HeaderNav} from './HeaderNav'
import styles from './Layout.module.scss'

const _Layout = ({title, children, wrapper = true, showChildren}) => (
  <div>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <HeaderNav />
    {showChildren ? (
      wrapper ? (
        <div className={styles.wrapper}>{children}</div>
      ) : (
        <div>{children}</div>
      )
    ) : (
      <div className={styles.wrapper}>There was an error with loading content.</div>
    )}
  </div>
)

export const Layout = connect((state, {needsContent}) => {
  return {
    showChildren: !needsContent || state.contentLoadSuccessful,
  }
})(_Layout)
