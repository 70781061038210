export const CARDS_CATEGORY = 'cards'

const getInitialCardsState = () => ({})

const getInitialState = () => ({
  people: getInitialCardsState(),
  projects: getInitialCardsState(),
})

export const setInitialState = (state) => {
  state[CARDS_CATEGORY] = getInitialState()
  return state
}
