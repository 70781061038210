import React from 'react'
import {BioSection} from './BioSection'
import {SkillsSection} from './SkillsSection'
import styles from './SkillsBox.module.scss'

const SkillsBox = (props) => {
  return (
    <BioSection type="sidebar">
      <div className={styles.content}>
        {props.languageSkills.length > 0 && (
          <SkillsSection key={'lang'} title="Spoken languages" skills={props.languageSkills} />
        )}
        {props.technicalSkills.map((skillCategory, index) => (
          <SkillsSection key={index} title={skillCategory.name} skills={skillCategory.skills} />
        ))}
      </div>
    </BioSection>
  )
}

export default SkillsBox
