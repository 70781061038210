import React from 'react'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css'
import styles from './MarkdownEditor.module.scss'
import {Markdown} from './Markdown'

export const MarkdownEditor = ({value, onChange}) => {
  return (
    <MdEditor
      className={styles.markdown}
      renderHTML={(text) => <Markdown source={text} />}
      onChange={({text}) => onChange(text)}
      value={value}
      placeholder={'Write something awesome... :)'}
    />
  )
}

export default MarkdownEditor
