import React, {Fragment} from 'react'
import {Card} from './Card'
import {Filter} from './Filter'
import styles from './Category.module.scss'
import {connect} from 'react-redux'
import {KEY_CATEGORY} from '../state/category'

const GroupSection = ({id, title, cards, cardIds}) => (
  <section
    className={styles.cards}
    id={id}
    style={{maxHeight: `${cardIds.length * 184}px`}} // CARD height + margins
    key={id}
  >
    <h2 className={styles.title}>{title}</h2>
    {cardIds.map((cardId) => (
      <Card card={cards[cardId]} key={cardId} />
    ))}
  </section>
)

class _Category extends React.Component {
  handleFilterClick({id, location}) {
    const {locations: cardLocations, cardGroups, filters, resetFilter, setFilters} = this.props
    const filter = location
      ? {name: 'locations', value: location, allValues: cardLocations}
      : {name: 'ids', value: id, allValues: Object.values(cardGroups)}

    if (filter.value === 'all') {
      resetFilter(filters, filter.name)
      return
    }

    const newFilters = filters

    // If filter does not contain value...
    if (!newFilters[filter.name].delete(filter.value)) {
      newFilters[filter.name].add(filter.value)
      newFilters[filter.name].delete('all')
    }
    // Last filter value was unchecked or all values were checked
    if (newFilters[filter.name].size === 0 || newFilters[filter.name].size === filter.allValues.length) {
      resetFilter(filters, filter.name)
      return
    }

    setFilters(newFilters)
  }

  getFilteredGroups(filters, cardGroups, locationGroups) {
    const groups = []

    if (filters.ids.has('all') && filters.locations.has('all')) {
      return Object.values(cardGroups)
    }

    filters.ids.forEach((category) => {
      filters.locations.forEach((location) => {
        if (location === 'all') {
          groups.push(cardGroups[category])
        } else if (category === 'all') {
          groups.push({
            id: location,
            title: location,
            cardIDs: locationGroups[location],
          })
        } else {
          groups.push({
            id: `${cardGroups[category].id}${location}`,
            title: `${cardGroups[category].title} in ${location}`,
            cardIDs: cardGroups[category].cardIDs.filter((cardId) => locationGroups[location].includes(cardId)),
          })
        }
      })
    })

    return groups
  }

  render() {
    const {cards, cardGroups, locations, locationGroups, filters, hideFilter, category} = this.props
    const groups = this.getFilteredGroups(filters, cardGroups, locationGroups)
    return (
      <Fragment>
        {!hideFilter && (
          <Filter
            locations={locations}
            cardGroups={cardGroups}
            filters={filters}
            category={category}
            handleFilterClick={(props) => this.handleFilterClick(props)}
          />
        )}

        {groups.map(({id, title, cardIDs}) => (
          <GroupSection id={id} key={id} title={title} cards={cards} cardIds={cardIDs} />
        ))}
      </Fragment>
    )
  }
}

function mapDispatchToProps(dispatch, {category}) {
  return {
    resetFilter: (filters, filter = 'both') => {
      const idFilter = filter === 'ids' || filter === 'both' ? new Set(['all']) : filters.ids
      const locationFilter = filter === 'locations' || filter === 'both' ? new Set(['all']) : filters.locations
      return dispatch({
        type: 'Reset filter',
        path: [KEY_CATEGORY, category],
        reducer: (state) => ({
          ...state,
          filters: {ids: idFilter, locations: locationFilter},
        }),
      })
    },
    setFilters: (filters) =>
      dispatch({
        type: 'Set filters',
        path: [KEY_CATEGORY, category],
        reducer: (state) => ({
          ...state,
          filters: {ids: new Set(filters.ids), locations: new Set(filters.locations)},
        }),
      }),
  }
}

export default connect((state, {category, source}) => {
  const categoryState = state.category[category]
  const categorySource = source || category
  const cards = state.cards[categorySource]
  return {
    filters: categoryState.filters || {ids: new Set(['all']), locations: new Set(['all'])},
    cardGroups: categoryState.cardGroups,
    locationGroups: categoryState.locationGroups,
    locations: Object.keys(categoryState.locationGroups),
    cards,
  }
}, mapDispatchToProps)(_Category)
