import React from 'react'
import {Markdown} from '../Markdown'
import styles from './Reference.module.scss'

export const Reference = ({representativeName, quote, representativePosition, representativePhoto}) => (
  <div className={styles.container}>
    <div className={styles.reference}>
      <Markdown className={styles.text} source={quote} />
      <div className={styles.person}>
        <img className={styles.photo} src={representativePhoto} alt="" />
        <div className={styles.info}>
          <div className={styles.name}>{representativeName}</div>
          <Markdown className={styles.position} source={representativePosition} />
        </div>
      </div>
    </div>
  </div>
)
