import React from 'react'
import {connect} from 'react-redux'
import styles from '../common.module.scss'
import Project from './Project'
import CollapsibleGroup from '../CollapsibleGroup'
import {ConfirmModal} from '../ConfirmModal'
import toast from 'react-hot-toast'
import axios from 'axios'

class _ExperienceTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: props.items,
      selectedItemIndex: null,
      showConfirmationWindow: false,
    }
  }

  onSaveAndApprove = (index) => {
    this.setState({selectedItemIndex: index, showConfirmationWindow: true})
  }

  onUpdate = (index, data) => {
    this.setState({
      items: this.state.items
        .slice(0, index)
        .concat([{...this.state.items[index], experience: data}])
        .concat(this.state.items.slice(index + 1)),
    })
  }

  onSave = (username, experience) => {
    this.props.updateExperience(username, experience)
  }

  handleConfirmationWindowResult = (result) => {
    if (result) {
      this.saveExperience(this.state.selectedItemIndex, true)
    } else {
      this.setState({selectedItemIndex: null, showConfirmationWindow: false})
    }
  }

  saveExperience = (index, approve = undefined) => {
    const self = this
    self.setState({showConfirmationWindow: false})
    axios
      .post('/proofreader/experience', {
        username: this.state.items[index].person.slug,
        experience: this.state.items[index].experience,
        approve,
      })
      .then(function (response) {
        toast.success(`The experience was ${approve ? 'approved' : 'saved'} successfully.`)
        self.onSave(self.state.items[index].person.slug, response.data.experience)
        if (approve) {
          self.setState({
            selectedItemIndex: null,
            items: self.state.items
              .slice(0, self.state.selectedItemIndex)
              .concat(self.state.items.slice(self.state.selectedItemIndex + 1)),
          })
        }
      })
      .catch(function (error) {
        toast.error('Server error!')
        console.log(error) // eslint-disable-line no-console
        self.setState({selectedItemIndex: null})
      })
  }

  render() {
    return (
      <>
        <div className={styles.headingWithButtonContainer}>
          <h3 className={styles.heading}>Experience</h3>
        </div>
        <CollapsibleGroup
          data={this.state.items}
          getElementFromData={(exp, index) => (
            <Project project={exp.experience} isVacuum onProjectUpdate={(data) => this.onUpdate(index, data)} />
          )}
          getTitleFromData={(i) =>
            `${i.person.name} - ${i.experience.project ? i.experience.project.projectName : 'Unknown Project'}`
          }
          openIndices={null}
          buttons={[
            {title: 'Save', onClick: (index) => this.saveExperience(index)},
            {title: 'Save & Approve', onClick: (index) => this.onSaveAndApprove(index)},
          ]}
        />
        {this.state.items.length === 0 && <div>All experience records are approved!</div>}
        {this.state.showConfirmationWindow && (
          <ConfirmModal
            handleResult={this.handleConfirmationWindowResult}
            confirmButtonText="Approve"
            title="Approve Experience"
            message={`Are you sure you want to approve the experience of "${
              this.state.items[this.state.selectedItemIndex].person.name
            }"?`}
          />
        )}
        <div className={styles.gap320} />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateExperience: (jiraID, workExperience) =>
      dispatch({
        type: 'Update Person Experience',
        path: ['accessiblePeople', jiraID],
        reducer: (state) => ({
          ...state,
          workExperience,
        }),
      }),
  }
}

export default connect((state) => {
  const items = []
  for (const person of Object.values(state.accessiblePeople)) {
    const exp = person.workExperience
      .filter(
        (exp) =>
          (!exp.proofread.contribution && exp.contribution !== '') ||
          (!exp.proofread.highlight && exp.highlight !== ''),
      )
      .map((exp) => ({person, experience: exp}))
    items.push(...exp)
  }
  return {
    user: state.user,
    items,
  }
}, mapDispatchToProps)(_ExperienceTab)
