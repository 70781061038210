import React from 'react'
import styles from './Header.module.scss'
import {PDFButton} from './PDFButton'
import defaultPersonImage from '../../assets/img/default-person.png'
import {PUBLIC_CV_PATH} from '../../consts/cv'

export const Header = ({name, position, image, cvId, hideCVButton}) => {
  return (
    <header className={styles.header}>
      <div className={styles.photo}>
        <img src={image ? `${image}?h=300` : defaultPersonImage} alt={name} className={styles.photoSrc} />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <span className={styles.name}>{name}</span>
          <span className={styles.position}>{position}</span>
        </div>
        <div className={styles.actions}>
          {!!cvId && <PDFButton cvId={cvId} parent="headerbutton" />}
          {!hideCVButton && cvId && (
            <a
              title="Show public CV in a new tab"
              href={`${window.location.origin}${PUBLIC_CV_PATH}/${cvId}`}
              target="_blank"
              rel="noreferrer"
              className={styles.cvButton}
            >
              <i className="fa fa-share-square" aria-hidden="true" />
              <span>View shareable CV</span>
            </a>
          )}
        </div>
      </div>
    </header>
  )
}
