export const KEY_HEADER = 'header'

const getInitialState = () => ({
  creatingPDF: false,
})

export const setInitialState = (state) => {
  state[KEY_HEADER] = getInitialState()
  return state
}
