import axios from 'axios'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import toast from 'react-hot-toast'
import commonStyles from './common.module.scss'
import styles from './EnhanceWithAiButton.module.scss'

const enhanceEndpoint = '/enhance-text-with-ai'
const defaultSuccessMessage = 'Enhanced text was successfully received'
const defaultErrorMessage = 'Server error!'

const noop = () => {
  return
}

export const uiStates = {
  default: 'default',
  loading: 'loading',
  preview: 'preview',
  retry: 'retry',
  saving: 'saving',
}

// NOTE: KEEP IN SYNC WITH `aiPrompts` in `server\src\frontend.js`!
export const promptTypes = {
  bio: 'bio',
  projectContribution: 'projectContribution',
  projectHighlights: 'projectHighlights',
  projectDescription: 'projectDescription',
  accomplishmentDescription: 'accomplishmentDescription',
}

const processingText = 'Processing'

export default function EnhanceWithAiButton({
  onAccept = noop,
  onSuccess = noop,
  onError = noop,
  onRedo = noop,
  onUiStateChange = noop,
  successMessage = defaultSuccessMessage,
  errorMessage = defaultErrorMessage,
  originalText = '',
  promptType,
}) {
  // Effect to check for required prop
  useEffect(() => {
    if (!promptType || !(promptType in promptTypes)) {
      throw Error('(EnhanceWithAiButton) Missing `promptType` prop!')
    }
  }, [promptType])

  const [uiState, setUiState] = useState(uiStates.default)
  const [aiText, setAiText] = useState('')

  const showEnhanceButton = [uiStates.default, uiStates.loading].includes(uiState)
  const showEditContent = [uiStates.preview, uiStates.retry, uiStates.saving].includes(uiState)
  const buttonsDisabled = [uiStates.loading, uiStates.saving, uiStates.retry].includes(uiState)

  const [animatedText, setAnimatedText] = useState(processingText) // The processing may take more than 10 seconds, so I decided to add some simple animations to let user know that something is happening
  const intervalIdRef = useRef(null)

  const clearAnimation = useCallback(() => {
    if (intervalIdRef.current !== null) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
    }
  }, [])

  // cleanup effect for setInterval
  useEffect(() => {
    return () => clearAnimation()
    // NOTE: this should run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // effect to trigger onUiStateChange anytime the uiState changes
  useEffect(() => {
    onUiStateChange(uiState)
  }, [onUiStateChange, uiState])

  const _onSuccess = useCallback(
    (data) => {
      setUiState(uiStates.preview)
      toast.success(successMessage)
      setAiText(data)
      onSuccess(data)
    },
    [onSuccess, successMessage],
  )

  const _onError = useCallback(
    (error, isRetry) => {
      setUiState(isRetry ? uiStates.preview : uiStates.default)
      toast.error(errorMessage)
      onError(error)
    },
    [errorMessage, onError],
  )

  const _onAccept = useCallback(() => {
    setUiState(uiStates.default)
    onAccept(aiText)
  }, [onAccept, aiText])

  const _onRedo = useCallback(() => {
    setUiState(uiStates.default)
    setAiText('')
    onRedo()
  }, [onRedo])

  const fetchEnhancedText = useCallback(
    (isRetry) => {
      setUiState(isRetry ? uiStates.retry : uiStates.loading)
      intervalIdRef.current = setInterval(
        () =>
          setAnimatedText((oldText) => (oldText.length < processingText.length + 3 ? `${oldText}.` : processingText)),
        500,
      )
      axios
        .post(enhanceEndpoint, {text: originalText, promptType})
        .then((response) => _onSuccess(response.data))
        .catch((e) => _onError(e, isRetry))
        .finally(clearAnimation)
    },
    [originalText, promptType, clearAnimation, _onSuccess, _onError],
  )

  const onEnhanceClick = useCallback(() => fetchEnhancedText(false), [fetchEnhancedText])

  const onRetryClick = useCallback(() => fetchEnhancedText(true), [fetchEnhancedText])

  return (
    <>
      {showEnhanceButton && (
        <button disabled={buttonsDisabled || !originalText} className={styles.enhanceButton} onClick={onEnhanceClick}>
          {uiState === uiStates.default ? '✨ Enhance ✨' : animatedText}
        </button>
      )}
      {showEditContent && (
        <div className={styles.aiTextWrapper}>
          <div>
            <h3 className={styles.title}>Suggested text:</h3>
          </div>
          <pre className={styles.suggestedText}>{aiText}</pre>
          <div className={styles.buttonGroup}>
            <button disabled={buttonsDisabled} className={commonStyles.secondaryButton} onClick={_onRedo}>
              Close
            </button>
            <button disabled={buttonsDisabled} className={commonStyles.secondaryButton} onClick={onRetryClick}>
              Retry
            </button>
            <button disabled={buttonsDisabled} className={commonStyles.primaryButton} onClick={_onAccept}>
              {uiState === uiStates.saving ? 'Saving' : 'Accept'}
            </button>
          </div>
        </div>
      )}
    </>
  )
}
