import React from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import styles from './ProfileTabs.module.scss'
import {KEY_UPDATE_PROFILE_TAB} from '../../state/updateProfileTab'

const _tabs = ['Bio', 'Tech Skills', 'Experience', 'Previous Experience', 'Accomplishments', 'Education']

class _ProfileTabs extends React.Component {
  constructor() {
    super()

    this.state = {
      itemsExpanded: false,
    }
  }

  componentWillUnmount() {
    const {setActiveTab} = this.props
    setActiveTab('')
  }

  handleTabClick = (handler, id) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    this.setState({itemsExpanded: false})

    handler(id)
  }

  render() {
    const {setActiveTab, activeTab} = this.props
    const currentTab = activeTab || 'Bio'

    return (
      <div className={styles.submenu}>
        <ul className={styles.submenuContent}>
          {_tabs.map(
            (tab) =>
              (this.state.itemsExpanded || currentTab === tab || window.innerWidth >= 1124) && (
                <li
                  key={tab}
                  className={classNames(styles.item, currentTab === tab && styles.isActive)}
                  onClick={() => this.handleTabClick(setActiveTab, tab)}
                >
                  {`${tab} ${this.props.unsavedTabs.indexOf(tab) !== -1 ? '*' : ''}`}
                </li>
              ),
          )}
          <button
            className={styles.expandArrow}
            onClick={() => {
              this.setState({itemsExpanded: !this.state.itemsExpanded})
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              width="48"
              fill="#e72076"
              style={{transform: this.state.itemsExpanded ? 'rotate(180deg)' : 'none'}}
            >
              <path d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z" />
            </svg>
          </button>
        </ul>
      </div>
    )
  }
}

export const ProfileTabs = connect(
  (state) => ({
    activeTab: state.updateProfileTab.activeTab,
  }),
  (dispatch) => ({
    setActiveTab: (id) =>
      dispatch({
        type: 'Set active tab',
        path: [KEY_UPDATE_PROFILE_TAB],
        reducer: (state) => ({
          ...state,
          activeTab: id,
        }),
      }),
  }),
)(_ProfileTabs)
