import React from 'react'
import classNames from 'classnames'
import {Markdown} from '../Markdown'
import {BioSection} from './BioSection'
import styles from './About.module.scss'

export const About = ({contract, location, bio, gitHubProfile, slackUserId}) => (
  <BioSection type="sidebar">
    <article className={styles.content}>
      <dl className={styles.info}>
        {contract && (
          <div className={classNames(styles.item, styles.webOnly)}>
            <dt className={styles.dt}>Contract</dt>
            <dd className={styles.dd}>{contract}</dd>
          </div>
        )}
        <div className={styles.item}>
          <dt className={styles.dt}>Location</dt>
          <dd className={styles.dd}>{location}</dd>
        </div>
        {gitHubProfile && (
          <div className={styles.item}>
            <dt className={styles.dt}>GitHub</dt>
            <dd className={styles.dd}>
              <a target="_blank" rel="noopener noreferrer" href={`http://github.com/${gitHubProfile}`}>
                {gitHubProfile}
              </a>
            </dd>
          </div>
        )}
        {slackUserId && (
          <div className={classNames(styles.item, styles.webOnly)}>
            <dt className={styles.dt}>Slack ID</dt>
            <dd className={styles.dd}>
              <a target="_blank" rel="noopener noreferrer" href={`slack://user?team=T026LE24D&id=${slackUserId}`}>
                {slackUserId}
              </a>
            </dd>
          </div>
        )}
      </dl>
      <Markdown className={styles.bio} source={bio} />
    </article>
  </BioSection>
)
