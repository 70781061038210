import React from 'react'
import {Markdown} from '../Markdown'
import {BioSection} from './BioSection'
import styles from './AccomplishmentsBox.module.scss'

export const AccomplishmentsBox = ({accomplishments}) => (
  <BioSection title="Accomplishments" type="sidebar">
    {accomplishments.map(({description, title}, index) => (
      <div className={styles.accomplishment} key={index}>
        <p className={styles.title}>{title}</p>
        <Markdown className={styles.description} source={description} />
      </div>
    ))}
  </BioSection>
)
