import React from 'react'
import {connect} from 'react-redux'
import styles from '../common.module.scss'
import CollapsibleGroup from '../CollapsibleGroup'
import Bio from './Bio'
import {ConfirmModal} from '../ConfirmModal'
import toast from 'react-hot-toast'
import axios from 'axios'

class _BioTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: props.items,
      selectedItemIndex: null,
      showConfirmationWindow: false,
    }
  }

  onSaveAndApprove = (index) => {
    this.setState({selectedItemIndex: index, showConfirmationWindow: true})
  }

  onUpdate = (index, data) => {
    this.setState({
      items: this.state.items
        .slice(0, index)
        .concat([{...this.state.items[index], bio: data}])
        .concat(this.state.items.slice(index + 1)),
    })
  }

  onSave = (username, bio, proofread) => {
    this.props.updateBio(username, bio, proofread)
  }

  handleConfirmationWindowResult = (result) => {
    if (result) {
      this.saveBio(this.state.selectedItemIndex, true)
    } else {
      this.setState({selectedItemIndex: null, showConfirmationWindow: false})
    }
  }

  saveBio = (index, approve = undefined) => {
    const self = this
    self.setState({showConfirmationWindow: false})
    axios
      .post('/proofreader/bio', {
        username: this.state.items[index].person.slug,
        bio: this.state.items[index].bio,
        approve,
      })
      .then(function (response) {
        toast.success(`The bio was ${approve ? 'approved' : 'saved'} successfully.`)
        self.onSave(self.state.items[index].person.slug, response.data.bio, response.data.proofread)
        if (approve) {
          self.setState({
            selectedItemIndex: null,
            items: self.state.items
              .slice(0, self.state.selectedItemIndex)
              .concat(self.state.items.slice(self.state.selectedItemIndex + 1)),
          })
        }
      })
      .catch(function (error) {
        toast.error('Server error!')
        console.log(error) // eslint-disable-line no-console
        self.setState({selectedItemIndex: null})
      })
  }

  render() {
    return (
      <>
        <div className={styles.headingWithButtonContainer}>
          <h3 className={styles.heading}>Bio</h3>
        </div>
        <CollapsibleGroup
          data={this.state.items}
          getElementFromData={(a, index) => <Bio bio={a.bio} onUpdate={(data) => this.onUpdate(index, data)} />}
          getTitleFromData={(a) => `${a.person.name} - Bio`}
          openIndices={null}
          buttons={[
            {title: 'Save', onClick: (index) => this.saveBio(index)},
            {title: 'Save & Approve', onClick: (index) => this.onSaveAndApprove(index)},
          ]}
        />
        {this.state.items.length === 0 && <div>All bio records are approved!</div>}
        {this.state.showConfirmationWindow && (
          <ConfirmModal
            handleResult={this.handleConfirmationWindowResult}
            confirmButtonText="Approve"
            title="Approve Bio"
            message={`Are you sure you want to approve the bio of "${
              this.state.items[this.state.selectedItemIndex].person.name
            }"?`}
          />
        )}
        <div className={styles.gap320} />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateBio: (jiraID, bio, proofread) =>
      dispatch({
        type: 'Update Person Bio',
        path: ['accessiblePeople', jiraID],
        reducer: (state) => ({
          ...state,
          bio,
          proofread,
        }),
      }),
  }
}

export default connect((state) => {
  const items = []
  for (const person of Object.values(state.accessiblePeople)) {
    if (!person.proofread && person.bio !== '') {
      items.push({person, bio: person.bio})
    }
  }
  return {
    user: state.user,
    items,
  }
}, mapDispatchToProps)(_BioTab)
