import React from 'react'
import styles from './common.module.scss'
import modalStyles from './ConfirmModal.module.scss'

export const ConfirmModal = ({
  handleResult,
  message = 'Are you sure?',
  confirmButtonText = 'Yes',
  cancelButtonText = 'Cancel',
  title = null,
}) => (
  <div>
    <div className={modalStyles.container}>
      {title && (
        <div className={modalStyles.titleContainer}>
          <div className={modalStyles.title}>{title}</div>
          <div className={modalStyles.closeButton} onClick={() => handleResult(false)}>
            X
          </div>
        </div>
      )}
      <div className={modalStyles.bodyContainer}>
        <div className={modalStyles.confirmationText}>{message}</div>
        <div className={modalStyles.buttonContainer}>
          <button className={styles.secondaryButton} onClick={() => handleResult(false)}>
            {cancelButtonText}
          </button>
          <button className={styles.primaryButton} onClick={() => handleResult(true)}>
            {confirmButtonText}
          </button>
        </div>
      </div>
    </div>
    <div className={modalStyles.background} onClick={() => handleResult(false)} />
  </div>
)
