import React from 'react'
import styles from '../common.module.scss'
import {connect} from 'react-redux'
import MarkdownEditor from '../MarkdownEditor'
import EnhanceWithAiButton, {promptTypes} from '../EnhanceWithAiButton'

class Accomplishment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accomplishment: props.accomplishment,
    }
  }

  handleUpdate = () => {
    if (this.props.onUpdate) {
      this.props.onUpdate(this.state.accomplishment)
    }
  }

  render() {
    return (
      <article className={styles.experience}>
        <div className={styles.content}>
          <div className={`${styles.label} ${styles.required}`}>Headline</div>
          <div className={styles.description}>
            If you don't want to think of a headline for each accomplishment, you can instead use a generic headline
            like&nbsp; "<i>Achievements</i>" or "<i>Certificates</i>" and write multiple entries within a single record.
          </div>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              size="100"
              name="headline"
              value={this.state.accomplishment.title}
              placeholder="Headline"
              onChange={(event) =>
                this.setState(
                  {accomplishment: {...this.state.accomplishment, title: event.target.value}},
                  this.handleUpdate,
                )
              }
            />
          </div>
          <div className={`${styles.label} ${styles.required}`}>Description</div>
          <div className={styles.description}>Describe the accomplishment. Keep it short and simple.</div>
          <br />
          <MarkdownEditor
            value={this.state.accomplishment.description}
            onChange={(val) =>
              this.setState({accomplishment: {...this.state.accomplishment, description: val}}, this.handleUpdate)
            }
          />
          <EnhanceWithAiButton
            originalText={this.state.accomplishment.description}
            promptType={promptTypes.accomplishmentDescription}
            onAccept={(val) =>
              this.setState({accomplishment: {...this.state.accomplishment, description: val}}, this.handleUpdate)
            }
          />
          <br />
          <br />
        </div>
      </article>
    )
  }
}

export default connect((state, {accomplishment}) => ({
  accomplishment: {
    id: accomplishment.id ?? null,
    title: accomplishment.title ?? '',
    description: accomplishment.description ?? '',
    proofread: accomplishment.proofread,
  },
}))(Accomplishment)
