export const KEY_PROOFREAD_TAB = 'proofreadTab'

const getInitialState = () => ({
  activeTab: '',
})

export const setInitialState = (state) => {
  state[KEY_PROOFREAD_TAB] = getInitialState()
  return state
}
