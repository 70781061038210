import React from 'react'
import commonStyles from './common.module.scss'
import styles from './Collapsible.module.scss'

class Collapsible extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.isOpen,
    }
  }

  handleToggle = (val) => {
    this.setState({isOpen: val})
  }

  handleButton = (event, handler) => {
    event.stopPropagation()
    handler()
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div>
          <div className={styles.header} onClick={() => this.handleToggle(!this.state.isOpen)}>
            <div className={styles.title}>{this.props.title}</div>
            <div className={`${styles.arrow} ${this.state.isOpen ? styles.open : ''}`}>
              <svg width="20px" height="20px" viewBox="0 0 20 20">
                <path
                  fill="#494c4e"
                  d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436
                                        0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787
                                        0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                />
              </svg>
            </div>
            {this.props.buttons.map((button, index) => {
              return (
                <button
                  key={index}
                  className={`${commonStyles.secondaryButton} ${commonStyles.nowrap} ${commonStyles.compact}`}
                  onClick={(event) => this.handleButton(event, button.onClick)}
                >
                  {button.title}
                </button>
              )
            })}
          </div>
          <div className={`${styles.content} ${this.state.isOpen ? styles.open : ''}`}>
            {this.state.isOpen && this.props.content}
          </div>
        </div>
      </div>
    )
  }
}

export default Collapsible
