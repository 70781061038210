import React from 'react'
import {Rating} from './Rating'
import styles from './SkillsSection.module.scss'

export const SkillsSection = ({title, skills, description}) => (
  <div className={styles.skill}>
    <h3 className={styles.title}>{title}</h3>
    {skills && (
      <div className={styles.content}>
        <table width="100%">
          <tbody>
            {skills.map(({label, value}, index) => (
              <Rating skill={label} value={value} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    )}
    {description && <p className={styles.description}>{description}</p>}
  </div>
)
