import React from 'react'
import classNames from 'classnames'
import styles from './BioSection.module.scss'

export const BioSection = ({title, id, type, children}) => (
  <section id={id} className={type === 'sidebar' ? styles.sidebar : styles.section}>
    {title && <h3 className={classNames(styles.title, type === 'sidebar' && styles.sidebarTitle)}>{title}</h3>}
    {children}
  </section>
)
