import React from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import styles from './ProfileSubmenu.module.scss'
import {KEY_PROFILE_SUBMENU} from '../../state/profileSubmenu'
import {isEmpty} from 'lodash'

class _ProfileSubmenu extends React.Component {
  componentWillUnmount() {
    const {setActiveTab} = this.props
    setActiveTab('')
  }

  handleTabClick = (handler, element, id) => {
    const position = element.offsetTop
    const padding = window.innerWidth < 768 ? 350 : 390

    window.scrollTo({
      top: position + padding,
      behavior: 'smooth',
    })

    handler(id)
  }

  render() {
    const {
      workExperience,
      previousExperience,
      priorityExperience,
      education,
      setActiveTab,
      activeTab,
      isSticky,
    } = this.props
    return (
      <ul className={isSticky ? styles.submenuSticky : styles.submenu}>
        {!isEmpty(priorityExperience) && (
          <li
            className={classNames(styles.item, activeTab === 'priorityExperience' && styles.isActive)}
            onClick={() =>
              this.handleTabClick(setActiveTab, document.getElementById('priorityExperience'), 'priorityExperience')
            }
          >
            My Experience
          </li>
        )}
        {!isEmpty(workExperience) && (
          <li
            className={classNames(styles.item, activeTab === 'projects' && styles.isActive)}
            onClick={() => this.handleTabClick(setActiveTab, document.getElementById('projects'), 'projects')}
          >
            Projects
          </li>
        )}
        {!isEmpty(previousExperience) && (
          <li
            className={classNames(styles.item, activeTab === 'experience' && styles.isActive)}
            onClick={() => this.handleTabClick(setActiveTab, document.getElementById('experience'), 'experience')}
          >
            Previous Experience
          </li>
        )}
        {!isEmpty(education) && (
          <li
            className={classNames(styles.item, activeTab === 'education' && styles.isActive)}
            onClick={() => this.handleTabClick(setActiveTab, document.getElementById('education'), 'education')}
          >
            Education
          </li>
        )}
      </ul>
    )
  }
}

export const ProfileSubmenu = connect(
  (state) => ({
    activeTab: state.profileSubmenu.activeTab,
  }),
  (dispatch) => ({
    setActiveTab: (id) =>
      dispatch({
        type: 'Set active tab',
        path: [KEY_PROFILE_SUBMENU],
        reducer: (state) => ({
          ...state,
          activeTab: id,
        }),
      }),
  }),
)(_ProfileSubmenu)
