import React from 'react'
import {Layout} from './components/Layout'
import {connect} from 'react-redux'
import {ProfileTabs} from './components/updateprofile/ProfileTabs'
import BioTab from './components/updateprofile/BioTab'
import TechSkillsTab from './components/updateprofile/TechSkillsTab'
import ExperienceTab from './components/updateprofile/ExperienceTab'
import PreviousExperienceTab from './components/updateprofile/PreviousExperienceTab'
import AccomplishmentsTab from './components/updateprofile/AccomplishmentsTab'
import EducationTab from './components/updateprofile/EducationTab'
import styles from './ProfileUpdate.module.scss'
import {Dialog} from './components/Dialog'

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      unsavedTabs: [],
      helpWindow: false,
    }
  }

  addUnsavedTab = (tab) => {
    const index = this.state.unsavedTabs.indexOf(tab)
    if (index === -1) {
      this.setState({
        unsavedTabs: [...this.state.unsavedTabs].concat([tab]),
      })
    }
  }

  removeUnsavedTab = (tab) => {
    const index = this.state.unsavedTabs.indexOf(tab)
    if (index >= 0) {
      this.setState({
        unsavedTabs: this.state.unsavedTabs.slice(0, index).concat(this.state.unsavedTabs.slice(index + 1)),
      })
    }
  }

  render() {
    const currentTab = this.props.activeTab || 'Bio'
    return (
      <Layout title={'Update Profile'} needsContent>
        <div className={styles.navigationWrapper}>
          <ProfileTabs unsavedTabs={this.state.unsavedTabs} />
          <button
            className={styles.helpIcon}
            onClick={() => {
              this.setState({helpWindow: !this.state.helpWindow})
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="#e72076">
              <path d="M24.2 35.65q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55Zm-1.75-7.3h2.95q0-1.3.325-2.375T27.75 23.5q1.55-1.3 2.2-2.55.65-1.25.65-2.75 0-2.65-1.725-4.25t-4.575-1.6q-2.45 0-4.325 1.225T17.25 16.95l2.65 1q.55-1.4 1.65-2.175 1.1-.775 2.6-.775 1.7 0 2.75.925t1.05 2.375q0 1.1-.65 2.075-.65.975-1.9 2.025-1.5 1.3-2.225 2.575-.725 1.275-.725 3.375ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z" />
            </svg>
          </button>
        </div>
        <div>
          {currentTab === 'Bio' && (
            <BioTab onUpdate={() => this.addUnsavedTab('Bio')} onSave={() => this.removeUnsavedTab('Bio')} />
          )}
          {currentTab === 'Tech Skills' && (
            <TechSkillsTab
              onUpdate={() => this.addUnsavedTab('Tech Skills')}
              onSave={() => this.removeUnsavedTab('Tech Skills')}
            />
          )}
          {currentTab === 'Experience' && (
            <ExperienceTab
              onUpdate={() => this.addUnsavedTab('Experience')}
              onSave={() => this.removeUnsavedTab('Experience')}
            />
          )}
          {currentTab === 'Previous Experience' && (
            <PreviousExperienceTab
              onUpdate={() => this.addUnsavedTab('Previous Experience')}
              onSave={() => this.removeUnsavedTab('Previous Experience')}
            />
          )}
          {currentTab === 'Accomplishments' && (
            <AccomplishmentsTab
              onUpdate={() => this.addUnsavedTab('Accomplishments')}
              onSave={() => this.removeUnsavedTab('Accomplishments')}
            />
          )}
          {currentTab === 'Education' && (
            <EducationTab
              onUpdate={() => this.addUnsavedTab('Education')}
              onSave={() => this.removeUnsavedTab('Education')}
            />
          )}
        </div>
        <Dialog
          title="TIPS and TRICKS"
          open={this.state.helpWindow}
          setClose={() => this.setState({helpWindow: false})}
        >
          <div>
            <ul className={styles.tips}>
              <li>
                Provide some specifics of your work. Communicate more than just "I was doing some development". Mention
                1-2 very specific issues you've managed to deliver.
              </li>
              <li>
                Were you leading other people, coaching juniors and interns, etc.? Were you communicating with the
                customer or the Product Owner? Mention that.
              </li>
              <li>
                Don’t worry about perfect English and pretty sentences. We will have a proofreader review this when
                you’re done.
              </li>
              <li>Don't be too humble, be bold and stand up straight with your shoulders back.</li>
            </ul>
          </div>
        </Dialog>
      </Layout>
    )
  }
}

export default connect((state) => ({
  activeTab: state.updateProfileTab.activeTab,
}))(Profile)
