export const KEY_USER = 'user'

const getInitialState = () => ({
  username: '',
  admin: false,
  proofreader: false,
  linkToken: false,
})

export const setInitialState = (state) => {
  state[KEY_USER] = getInitialState()
  return state
}
