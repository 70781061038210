import React from 'react'
import {Layout} from './components/Layout'
import styles from './Organisation.module.scss'
import orgStructureImage from './assets/img/orgstructure.png'

const Organisation = () => (
  <Layout title="Vacuumpeople - Org Structure" needsContent>
    <div className={styles.wrapper}>
      <img src={orgStructureImage} alt="Organisation structure" className={styles.orgImage} />
    </div>
  </Layout>
)

export default Organisation
