import React from 'react'
import {Redirect} from 'react-router-dom'
import {Layout} from './components/Layout'
import {People} from './components/project/People'
import {Extras} from './components/project/Extras'
import {Intro} from './components/project/Intro'
import {Highlights} from './components/project/Highlights'
import {Header} from './components/project/Header'
import {Showcase} from './components/project/Showcase'
import {Reference} from './components/project/Reference'
import {Breadcrumbs} from './components/Breadcrumbs'
import {connect} from 'react-redux'

class Project extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {slugAccessible, project, peopleCards} = this.props
    const {showcaseImage, endorsement, description, title, extras} = project || {}
    return !slugAccessible ? (
      <Redirect to="/projects" />
    ) : (
      <Layout title={`Vacuumpeople - ${title}`} wrapper={false} needsContent>
        <Header {...project}>
          <Breadcrumbs category="Projects" categorySlug="/projects" lastItem={title} />
        </Header>
        {description && <Intro description={description} />}
        <Highlights {...project} />
        {showcaseImage && <Showcase image={showcaseImage} />}
        {endorsement && <Reference {...endorsement} />}
        <People cards={peopleCards} />
        {extras && <Extras text={extras} />}
      </Layout>
    )
  }
}

export default connect(
  (
    state,
    {
      match: {
        params: {slug},
      },
    },
  ) => {
    const project = state.accessibleProjects[slug]
    return {
      slugAccessible: Object.prototype.hasOwnProperty.call(state.accessibleProjects, slug),
      project,
      peopleCards:
        project &&
        project.people.map(({id, access}) => ({
          ...state.cards.experience[id][project.id],
          access,
        })),
    }
  },
)(Project)
