import React from 'react'
import {Markdown} from '../Markdown'
import styles from './Intro.module.scss'

export const Intro = ({description}) => (
  <div className={styles.intro}>
    <div className={styles.wrapper}>
      <Markdown source={description} />
    </div>
  </div>
)
