import {applyMiddleware, createStore, compose} from 'redux'
import {createLogger} from 'redux-logger'

import {rootReducer, getInitialState} from './rootReducer'
import thunkMiddleware from 'redux-thunk'
import lodash from 'lodash'

export default function configureStore({initialState} = {}) {
  const loggerMiddleware = createLogger()

  const middlewares = [thunkMiddleware]

  let composeEnhancers = compose

  // process is defined by webpack
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(loggerMiddleware)

    // check for redux extension
    if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'vacuumpeople',
        serialize: true, // to serialize also functins (reduced-redux)
      })
    }
  }

  const enhancer = composeEnhancers(applyMiddleware(...middlewares))

  initialState = lodash.merge({}, getInitialState(), initialState)

  return createStore(rootReducer, initialState, enhancer)
}
