import React, {useState, useEffect} from 'react'
import styles from './Filter.module.scss'
import {Select} from './Select'
import {orderBy} from 'lodash'

export const Filter = ({category, locations, cardGroups, filters, handleFilterClick}) => {
  const [openedFilter, setOpenedFilter] = useState(null)
  const showLocationsFilter = category === 'people'
  const cardGroupLabel = category === 'people' ? 'Position' : 'Industry'
  const cardGroupOptions = orderBy(cardGroups, 'title', 'asc')
  const locationOptions = orderBy(
    locations.map((location) => ({id: location, title: location})),
    'title',
    'asc',
  )
  const handleClickOutside = (e) => {
    const filterControls = document.getElementsByClassName('filterControl')
    if (filterControls && !Array.from(filterControls).includes(e.target)) {
      setOpenedFilter(null)
    }
  }

  const toggleFilter = (filter) => {
    setOpenedFilter(openedFilter === filter ? null : filter)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
  }, [])

  return (
    <>
      <div className={styles.filter}>
        <Select
          filterId="id"
          title={cardGroupLabel}
          options={cardGroupOptions}
          selected={filters.ids}
          handleSelect={handleFilterClick}
          opened={openedFilter === 'cardGroups'}
          toggle={() => toggleFilter('cardGroups')}
        />
        {showLocationsFilter && (
          <Select
            filterId="location"
            title="Location"
            options={locationOptions}
            selected={filters.locations}
            handleSelect={handleFilterClick}
            opened={openedFilter === 'locations'}
            toggle={() => toggleFilter('locations')}
          />
        )}
      </div>
    </>
  )
}
