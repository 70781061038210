export const KEY_PROFILE_SUBMENU = 'profileSubmenu'

const getInitialState = () => ({
  activeTab: '',
})

export const setInitialState = (state) => {
  state[KEY_PROFILE_SUBMENU] = getInitialState()
  return state
}
