import {applyIn} from './helpers/util'
import {compose} from 'redux'

import {setInitialState as setLoginInitialState} from './state/login'
import {setInitialState as setHeaderInitialState} from './state/header'
import {setInitialState as setHeaderNavInitialState} from './state/headerNav'
import {setInitialState as setCategoryInitialState} from './state/category'
import {setInitialState as setCardsInitialState} from './state/cards'
import {setInitialState as setUserInitialState} from './state/user'
import {setInitialState as setPeopleInitialState} from './state/people'
import {setInitialState as setProfileSubmenuInitialState} from './state/profileSubmenu'
import {setInitialState as setUpdateProfileTabInitialState} from './state/updateProfileTab'
import {setInitialState as setProofreadTabInitialState} from './state/proofreadTab'
import {setInitialState as setProfileNavInitialState} from './state/profileNav'
import {setInitialState as setPersonInitialState} from './state/person'

export const getInitialState = () => {
  const state = {}

  return compose(
    setLoginInitialState,
    setHeaderInitialState,
    setHeaderNavInitialState,
    setCategoryInitialState,
    setCardsInitialState,
    setUserInitialState,
    setPeopleInitialState,
    setProfileSubmenuInitialState,
    setUpdateProfileTabInitialState,
    setProofreadTabInitialState,
    setProfileNavInitialState,
    setPersonInitialState,
  )(state)
}

export const rootReducer = (state = getInitialState(), action) => {
  if (!action.reducer) {
    return state // fallback in case not our action
  }
  if (action.path === undefined) {
    throw new Error(`Did you forget action.path in action ${action.type}`)
  }
  return applyIn(state, action.path, action.reducer)
}
