import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// Dates needs to be parsed using dayjs library in order to work correctly in Mozilla Firefox
dayjs.extend(customParseFormat)

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const parseDate = (dateStr) => {
  const date = dayjs(dateStr).toDate()
  return `${months[date.getMonth()]} ${date.getFullYear()}`
}

export const formatDate = (date) => {
  return date ? `${months[date.getMonth()].substring(0, 3)} ${date.getFullYear()}` : null
}

export const parseDateShort = (dateStr) => {
  if (!dateStr) {
    return null
  }

  const date = dayjs(dateStr, 'MMM YYYY').toDate()

  if (isNaN(date)) {
    return null
  } else {
    return date
  }
}

export const parseYear = (yearStr) => {
  if (!yearStr) {
    return null
  }

  // added conversion to string because sometimes the value can be in number format
  const date = dayjs(String(yearStr), 'YYYY').toDate()
  if (isNaN(date)) {
    return null
  } else {
    return date
  }
}

export const parseDuration = (startDate, endDate) => {
  if (!startDate && !endDate) return ''
  const startDateParsed = parseDate(startDate)
  const endDateParsed = endDate ? parseDate(endDate) : 'Present'
  const duration = startDateParsed === endDateParsed ? startDateParsed : `${startDateParsed} - ${endDateParsed}`
  return duration
}

export const parseUrl = (url) => {
  return url.replace(/^(https?:|)\/\//, '')
}
