export const KEY_HEADER_NAV = 'headerNav'

const getInitialState = () => ({
  showMenu: false,
  isActive: false,
  isRefreshing: false,
})

export const setInitialState = (state) => {
  state[KEY_HEADER_NAV] = getInitialState()
  return state
}
