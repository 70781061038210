import React from 'react'
import {Markdown} from '../Markdown'
import {ProjectContribution} from './ProjectContribution'
import styles from './Experience.module.scss'
import {Link} from 'react-router-dom'

export const PriorityExperience = ({
  title,
  description,
  contribution,
  duration,
  position,
  technologies,
  highlights,
  slug,
}) => (
  <article className={styles.experience}>
    <div className={styles.content}>
      {title && (
        <div className={styles.name}>
          <h3 className={styles.title}>{slug ? <Link to={`/projects/${slug}`}>{title}</Link> : <>{title}</>}</h3>
        </div>
      )}
      {(position || duration) && (
        <div className={styles.detail}>
          {position && <span className={styles.position}>{position}</span>}
          {duration && <span className={styles.date}>{duration}</span>}
        </div>
      )}
    </div>

    {description && <Markdown className={styles.description} source={description} />}

    <ProjectContribution contribution={contribution} highlights={highlights} technologies={technologies} />
  </article>
)
