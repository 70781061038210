import React from 'react'
import {Layout} from './components/Layout'
import {Link} from 'react-router-dom'
import styles from './Error.module.scss'

const Error = ({code}) => (
  <Layout title={'Vacuumpeople'}>
    <div className={styles.error}>
      <div className={styles.title}>
        {code === 404 && '404 - Not found'}
        {code === 403 && '403 - Forbidden'}
      </div>
      <div className={styles.back}>
        Back to <Link to="/">homepage</Link>.
      </div>
    </div>
  </Layout>
)

export const Error404 = () => <Error code={404} />
export const Error403 = () => <Error code={403} />
