import url from 'url'

export const apiRequest = async (apiPath) => {
  const options = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {},
  }

  const apiUrl = url.resolve('/api/', apiPath)
  const res = await fetch(apiUrl, options)
  if (res.status >= 400) return res
  return await res.json()
}
