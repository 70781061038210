import React from 'react'
import {Card} from '../Card'
import styles from './People.module.scss'

export const People = ({cards}) => (
  <div className={styles.people}>
    <div className={styles.wrapper}>
      <h2 className={styles.title}>People on the project</h2>
      <div className={styles.cards}>
        {cards.map((card, index) => (
          <Card card={card} key={index} />
        ))}
      </div>
    </div>
  </div>
)
