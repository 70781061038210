import React from 'react'
import {connect} from 'react-redux'
import styles from '../common.module.scss'
import SaveButton from '../SaveButton'
import CollapsibleGroup from '../CollapsibleGroup'
import Accomplishment from './Accomplishment'
import {ConfirmModal} from '../ConfirmModal'
import toast from 'react-hot-toast'
import axios from 'axios'

class _AccomplishmentsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accomplishments: props.accomplishments,
      openIndices: null,
      selectedItemIndex: null,
      showConfirmationWindow: false,
    }
  }

  componentWillUnmount() {
    this.props.updateAccomplishments(this.props.accessible, this.props.user.username, this.state.accomplishments)
  }

  addNewAccomplishment = () => {
    const newAccomplishment = {
      id: null,
      title: '',
      description: '',
      proofread: false,
    }
    this.setState({
      accomplishments: [newAccomplishment, ...this.state.accomplishments],
      openIndices: new Set([0]),
    })
  }

  removeAccomplishment = (index) => {
    this.setState({selectedItemIndex: index, showConfirmationWindow: true})
  }

  onUpdate = (index, data) => {
    this.setState({
      accomplishments: this.state.accomplishments
        .slice(0, index)
        .concat([data])
        .concat(this.state.accomplishments.slice(index + 1)),
    })

    if (this.props.onUpdate) {
      this.props.onUpdate()
    }
  }

  onSave = (accomplishments) => {
    this.props.updateAccomplishments(this.props.accessible, this.props.user.username, accomplishments)
    this.setState({accomplishments})

    if (this.props.onSave) {
      this.props.onSave()
    }
  }

  isValid = (accomplishment) => {
    return accomplishment.title !== '' && accomplishment.description !== ''
  }

  getAccomplishmentName = (accomplishment) => {
    return accomplishment.title ? accomplishment.title : 'New Accomplishment'
  }

  handleConfirmationWindowResult = (result) => {
    if (result) {
      const accomplishment = this.state.accomplishments[this.state.selectedItemIndex]
      if (accomplishment.id !== null) {
        const self = this
        self.setState({showConfirmationWindow: false})
        axios
          .delete(`/accomplishment/${accomplishment.id}`)
          .then(function (response) {
            toast.success(`The accomplishment record was removed successfully.`)
            self.onSave(response.data.accomplishments)
            self.setState({
              selectedItemIndex: null,
              openIndices: null,
            })
          })
          .catch(function (error) {
            toast.error('Server error!')
            console.log(error) // eslint-disable-line no-console
            self.setState({selectedItemIndex: null})
          })
      } else {
        this.setState({
          selectedItemIndex: null,
          showConfirmationWindow: false,
          accomplishments: this.state.accomplishments
            .slice(0, this.state.selectedItemIndex)
            .concat(this.state.accomplishments.slice(this.state.selectedItemIndex + 1)),
          openIndices: null,
        })
      }
    } else {
      this.setState({selectedItemIndex: null, showConfirmationWindow: false})
    }
  }

  validateEntries = () => {
    const invalidEntries = []
    this.state.accomplishments.forEach((acc) => {
      if (!this.isValid(acc)) {
        invalidEntries.push(this.getAccomplishmentName(acc))
      }
    })

    const invalidMessage = invalidEntries ? `Mandatory fields missing: ${invalidEntries.join(', ')}` : ''

    return {result: invalidEntries.length === 0, message: invalidMessage}
  }

  render() {
    return (
      <>
        <h3 className={styles.heading}>Accomplishments</h3>
        <div className={styles.headingWithButtonContainer}>
          <div className={styles.description}>
            Mention any IT-related:
            <ul>
              <li>certificates</li>
              <li>courses, workshops</li>
              <li>competitions / games / hackathons (regardless of your result)</li>
              <li>projects you did in your free time</li>
              <li>publications / blogs</li>
            </ul>
            If you cannot decide whether something is worth sharing, the rule of thumb is to share it.
          </div>
          <button className={styles.primaryButton} onClick={this.addNewAccomplishment}>
            Add Accomplishment
          </button>
        </div>
        <CollapsibleGroup
          data={this.state.accomplishments}
          getElementFromData={(a, index) => (
            <Accomplishment accomplishment={a} onUpdate={(data) => this.onUpdate(index, data)} />
          )}
          getTitleFromData={(a) => this.getAccomplishmentName(a)}
          openIndices={this.state.openIndices}
          buttons={[{title: 'Remove', onClick: (index) => this.removeAccomplishment(index)}]}
        />
        <div className={styles.gap32} />
        <SaveButton
          successMessage="Your profile was updated successfully."
          endpointUrl="/accomplishments-update"
          payload={{accomplishments: this.state.accomplishments}}
          onSuccess={(response) => this.onSave(response.data.accomplishments)}
          validate={this.validateEntries}
        />
        {this.state.showConfirmationWindow && (
          <ConfirmModal
            handleResult={this.handleConfirmationWindowResult}
            confirmButtonText="Remove"
            title="Remove Accomplishment"
            message={`Are you sure you want to remove your accomplishment record "${
              this.state.accomplishments[this.state.selectedItemIndex].title
                ? this.state.accomplishments[this.state.selectedItemIndex].title
                : 'New Accomplishment'
            }"? This cannot be reversed!`}
          />
        )}
        <div className={styles.gap320} />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateAccomplishments: (accessible, jiraID, accomplishments) =>
      dispatch({
        type: 'Update Person Accomplishments',
        path: [accessible ? 'accessiblePeople' : 'offboardedPeople', jiraID],
        reducer: (state) => ({
          ...state,
          accomplishments,
        }),
      }),
  }
}

export default connect((state) => {
  const isPersonOffboarded = !!state.offboardedPeople[state.user.username]
  const person = isPersonOffboarded
    ? state.offboardedPeople[state.user.username]
    : state.accessiblePeople[state.user.username]

  return {
    accessible: !isPersonOffboarded,
    user: state.user,
    accomplishments: person?.accomplishments ?? [],
  }
}, mapDispatchToProps)(_AccomplishmentsTab)
