import React from 'react'
import {connect} from 'react-redux'
import styles from '../common.module.scss'
import Project from './Project'
import SaveButton from '../SaveButton'
import CollapsibleGroup from '../CollapsibleGroup'
import {ConfirmModal} from '../ConfirmModal'
import toast from 'react-hot-toast'
import axios from 'axios'

class _ExperienceTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      experience: props.experience,
      openIndices: null,
      selectedItemIndex: null,
      showConfirmationWindow: false,
    }
  }

  componentWillUnmount() {
    this.props.updateExperience(this.props.accessible, this.props.user.username, this.state.experience)
  }

  addNewProject = () => {
    const newProject = {
      id: null,
      project: null,
      slug: '',
      contribution: '',
      duration: '',
      startDate: null,
      endDate: null,
      highlight: '',
      position: '',
      technologies: '',
    }
    this.setState({experience: [newProject, ...this.state.experience], openIndices: new Set([0])})
  }

  removeProject = (index) => {
    this.setState({selectedItemIndex: index, showConfirmationWindow: true})
  }

  onProjectUpdate = (index, data) => {
    this.setState({
      experience: this.state.experience
        .slice(0, index)
        .concat([data])
        .concat(this.state.experience.slice(index + 1)),
    })

    if (this.props.onUpdate) {
      this.props.onUpdate()
    }
  }

  onSave = (experience) => {
    this.props.updateExperience(this.props.accessible, this.props.user.username, experience)
    this.setState({experience})

    if (this.props.onSave) {
      this.props.onSave()
    }
  }

  handleConfirmationWindowResult = (result) => {
    if (result) {
      const experience = this.state.experience[this.state.selectedItemIndex]
      if (experience.id !== null) {
        const self = this
        self.setState({showConfirmationWindow: false})
        axios
          .delete(`/experience/${experience.id}`)
          .then(function (response) {
            toast.success(`The experience record was removed successfully.`)
            self.onSave(response.data.experience)
            self.setState({
              selectedItemIndex: null,
              openIndices: null,
            })
          })
          .catch(function (error) {
            toast.error('Server error!')
            console.log(error) // eslint-disable-line no-console
            self.setState({selectedItemIndex: null})
          })
      } else {
        this.setState({
          selectedItemIndex: null,
          showConfirmationWindow: false,
          experience: this.state.experience
            .slice(0, this.state.selectedItemIndex)
            .concat(this.state.experience.slice(this.state.selectedItemIndex + 1)),
          openIndices: null,
        })
      }
    } else {
      this.setState({selectedItemIndex: null, showConfirmationWindow: false})
    }
  }

  isValid = (project) => {
    return (
      project.project !== null &&
      project.contribution !== '' &&
      project.position !== '' &&
      project.technologies !== '' &&
      project.startDate !== null
    )
  }

  getProjectName = (project) => {
    return this.props.projects[project.slug] ? this.props.projects[project.slug].projectName : 'New Project'
  }

  validateEntries = () => {
    const invalidEntries = []
    this.state.experience.forEach((exp) => {
      if (!this.isValid(exp)) {
        invalidEntries.push(this.getProjectName(exp))
      }
    })

    const invalidMessage = invalidEntries ? `Mandatory fields missing: ${invalidEntries.join(', ')}` : ''

    return {result: invalidEntries.length === 0, message: invalidMessage}
  }

  render() {
    return (
      <>
        <h3 className={styles.heading}>Experience</h3>
        <div className={styles.headingWithButtonContainer}>
          <div className={styles.description}>
            Use this section for the projects you worked on with Vacuumlabs, including your current project.
          </div>
          <button className={styles.primaryButton} onClick={this.addNewProject}>
            Add Project
          </button>
        </div>
        <CollapsibleGroup
          data={this.state.experience}
          getElementFromData={(p, index) => (
            <Project project={p} isVacuum onProjectUpdate={(data) => this.onProjectUpdate(index, data)} />
          )}
          getTitleFromData={(p) => this.getProjectName(p)}
          openIndices={this.state.openIndices}
          buttons={[{title: 'Remove', onClick: (index) => this.removeProject(index)}]}
        />
        <div className={styles.gap32} />
        <SaveButton
          successMessage="Your profile was updated successfully."
          endpointUrl="/experience-update"
          payload={{experience: this.state.experience}}
          onSuccess={(response) => this.onSave(response.data.experience)}
          validate={this.validateEntries}
        />
        {this.state.showConfirmationWindow && (
          <ConfirmModal
            handleResult={this.handleConfirmationWindowResult}
            confirmButtonText="Remove"
            title="Remove Experience"
            message={`Are you sure you want to remove your experience record on project "${this.getProjectName(
              this.state.experience[this.state.selectedItemIndex],
            )}"? This cannot be reversed!`}
          />
        )}
        <div className={styles.gap320} />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateExperience: (accessible, jiraID, workExperience) =>
      dispatch({
        type: 'Update Person Experience',
        path: [accessible ? 'accessiblePeople' : 'offboardedPeople', jiraID],
        reducer: (state) => ({
          ...state,
          workExperience,
        }),
      }),
  }
}

export default connect((state) => {
  const isPersonOffboarded = !!state.offboardedPeople[state.user.username]
  const person = isPersonOffboarded
    ? state.offboardedPeople[state.user.username]
    : state.accessiblePeople[state.user.username]

  return {
    accessible: !isPersonOffboarded,
    user: state.user,
    experience: person?.workExperience ?? [],
    projects: state.accessibleProjects,
  }
}, mapDispatchToProps)(_ExperienceTab)
