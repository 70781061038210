export const KEY_PERSON = 'person'

const getInitialState = () => ({
  isVisible: false,
})

export const setInitialState = (state) => {
  state[KEY_PERSON] = getInitialState()
  return state
}
