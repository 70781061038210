import React from 'react'
import {Layout} from './components/Layout'
import styles from './components/common.module.scss'
import {connect} from 'react-redux'
import toast from 'react-hot-toast'
import axios from 'axios'

class UserManagement extends React.Component {
  copyLoginLink(person) {
    axios.get(`/link-token?jiraId=${person.slug}`).then((res) => {
      if (res.data && res.data.status) {
        navigator.clipboard.writeText(`${window.location.origin}/signon/${res.data.linkToken}`)
        toast.success(`The link was copied to the clipboard.`)
      } else {
        toast.error(`Server error!`)
      }
    })
  }

  render() {
    return (
      <Layout title="Vacuumpeople - User Management" needsContent>
        <table className={`table ${styles.leftAlignedHeader} ${styles.alternateColors}`} width="100%">
          <thead>
            <tr>
              <th width="30%">User</th>
              <th className={styles.skillGroupLevel} width="30%">
                Jira ID
              </th>
              <th width="10%">Active</th>
              <th width="15%">Profile</th>
              <th width="15%">Link</th>
            </tr>
          </thead>
          <tbody>
            {this.props.accessiblePeople.map((person, index) => (
              <tr key={index}>
                <td width="30%" className={`${styles.padding1}`}>
                  {person.name}
                </td>
                <td width="30%" className={`${styles.padding1}`}>
                  {person.slug}
                </td>
                <td width="10%" className={`${styles.padding1}`}>
                  ✅
                </td>
                <td width="15%">
                  <a
                    className={`${styles.textButton} ${styles.padding1}`}
                    href={`./people/${person.slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open Profile
                  </a>
                </td>
                <td width="15%">
                  <button
                    className={`${styles.textButton} ${styles.nowrap} ${styles.padding1}`}
                    onClick={() => this.copyLoginLink(person)}
                  >
                    Copy Login Link
                  </button>
                </td>
              </tr>
            ))}
            {this.props.offboardedPeople.map((person, index) => (
              <tr key={index}>
                <td width="30%" className={`${styles.padding1}`}>
                  {person.name}
                </td>
                <td width="30%" className={`${styles.padding1}`}>
                  {person.slug}
                </td>
                <td width="10%" className={`${styles.padding1}`}>
                  ❌
                </td>
                <td width="15%">
                  <a
                    className={`${styles.textButton} ${styles.padding1}`}
                    href={`./people/${person.slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open Profile
                  </a>
                </td>
                <td width="15%">
                  <button
                    className={`${styles.textButton} ${styles.nowrap} ${styles.padding1}`}
                    onClick={() => this.copyLoginLink(person)}
                  >
                    Copy Login Link
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Layout>
    )
  }
}

export default connect((state) => ({
  accessiblePeople: Object.values(state.accessiblePeople),
  offboardedPeople: Object.values(state.offboardedPeople),
}))(UserManagement)
