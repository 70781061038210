import React from 'react'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import styles from './Card.module.scss'
import defaultPersonImage from '../assets/img/default-person.png'
import defaultProjectLogoImage from '../assets/img/default-project-logo.png'

const CardWrapper = ({slug, children}) => {
  return (
    <Link to={slug} className={classNames(styles.wrapper, styles.active)}>
      {children}
    </Link>
  )
}

export const Card = ({card: {slug, type, image, title, subtitle, firstLine, secondLine, ribbon}}) => (
  <article className={styles.card}>
    <CardWrapper {...{slug}}>
      <figure className={type === 'project' ? styles.projectImage : styles.personImage}>
        <img
          src={image ? `${image}?h=300` : type === 'project' ? defaultProjectLogoImage : defaultPersonImage}
          alt={title}
          className={type === 'project' ? styles.projectImageSrc : styles.personImageSrc}
        />
      </figure>
      <section className={type === 'project' ? styles.projectContent : styles.personContent}>
        <div className={type === 'project' ? styles.projectHeader : styles.personHeader}>
          <p className={styles.title}>{title}</p>
          {ribbon && <div className={styles.ribbon}>{ribbon}</div>}
          {type === 'personOnProject' && <p className={styles.subtitle}>{subtitle}</p>}
          {type === 'person' && <p className={styles.firstLine}>{firstLine}</p>}
        </div>
        <p className={type === 'project' ? styles.description : styles.secondLine}>{secondLine}</p>
      </section>
    </CardWrapper>
  </article>
)
